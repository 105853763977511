import React from "react";

function Program() {
  return (
    <div id="program" className="section_prg">
      <img
        className="bg_image pc_only"
        src="/assets/images/forbiz/bg_wave.svg"
      />
      <img
        className="bg_image sp_only"
        src="/assets/images/forbiz/bg_wave_sp.svg"
      />
      <div className="wrap_contents">
        <div className="content_block">
          <h1>
            INCUBATION &<br />
            ACCELERATION PROGRAM
          </h1>
          <p className="subtitle">
            社内起業家向け事業立ち上げ研修の設計から運用まで、
            <br className="pc_only" />
            またそこから生まれたアイデアの事業化を支援するプログラム
          </p>
        </div>
        <div className="content_block">
          <img
            className="flow_image pc_only"
            src="/assets/images/forbiz/program.svg"
          />
        </div>
        <div className="content_block">
          <div>
            <img
              className="flow_image sp_only"
              src="/assets/images/forbiz/program_01_sp.svg"
            />
            <p>
              戦略立案、人事制度設計・評価基準作り、運営システム構築（ACCEL
              INTRA）
            </p>
          </div>
          <div>
            <img
              className="flow_image sp_only"
              src="/assets/images/forbiz/program_02_sp.svg"
            />
            <p>
              社内広報、勉強会/ワークショップ/ハッカソン開催、事業メンタリング/ユーザーヒアリング/POC支援、Demoday
            </p>
          </div>
          <div>
            <img
              className="flow_image sp_only"
              src="/assets/images/forbiz/program_03_sp.svg"
            />
            <p>
              プロダクト開発支援、営業/マーケティング支援、プロジェクト資金調達支援
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Program;
