import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col,} from 'reactstrap';
import data from '../data/faq.json';
import {checkText} from '../utils/common.js';
const Faq = (props) => {
  const [stateTab, setStateTab] = useState({ activeTab: 1});
  const [stateToggle1, setStateToggle1] = useState({ active: false});
  const [stateToggle2, setStateToggle2] = useState({ active: false});
  const [stateToggle3, setStateToggle3] = useState({ active: false});
  const [stateToggle4, setStateToggle4] = useState({ active: false});
  const [stateToggle5, setStateToggle5] = useState({ active: false});
  const [stateToggle6, setStateToggle6] = useState({ active: false});
  const [stateToggle7, setStateToggle7] = useState({ active: false});
  const [stateToggle8, setStateToggle8] = useState({ active: false});
  const [stateToggle9, setStateToggle9] = useState({ active: false});
  const [stateToggle10, setStateToggle10] = useState({ active: false});
  const [stateToggle11, setStateToggle11] = useState({ active: false});
  const [stateToggle12, setStateToggle12] = useState({ active: false});
  const [stateToggle13, setStateToggle13] = useState({ active: false});

  const [text, setText] = useState(data[props.lang]);
  const handleStateChange = (val) => {
    // e.preventDefault();
    setStateTab({activeTab: val});
  }
  const handleToggleChange = (val) => {
    // e.preventDefault();
    switch (val) {
      case 1:
        setStateToggle1({active: !stateToggle1.active});
        break;
      case 2:
        setStateToggle2({active: !stateToggle2.active});
        break;
      case 3:
        setStateToggle3({active: !stateToggle3.active});
        break;
      case 4:
        setStateToggle4({active: !stateToggle4.active});
        break;
      case 5:
        setStateToggle5({active: !stateToggle5.active});
        break;
      case 6:
        setStateToggle6({active: !stateToggle6.active});
        break;
      case 7:
        setStateToggle7({active: !stateToggle7.active});
        break;
      case 8:
        setStateToggle8({active: !stateToggle8.active});
        break;
      case 9:
        setStateToggle9({active: !stateToggle9.active});
        break;
      case 10:
        setStateToggle10({active: !stateToggle10.active});
        break;
      case 11:
        setStateToggle11({active: !stateToggle11.active});
        break;
      case 12:
        setStateToggle12({active: !stateToggle12.active});
        break;
      case 13:
        setStateToggle13({active: !stateToggle13.active});
        break;
      default:
        break;
    }
  }
  return (
      <section className="container-faq">
				<Container>
					<h2>FAQ</h2>
					<div className="content-wrap mt-5">
						<Row>
            <Col xs="12">
                <div className="toggle-nav list-menu">
									<ul className="list-inline line-dot-bottom">
											<li className={ stateTab.activeTab === 1 ? "active list-inline-item" : "list-inline-item" } onClick={() => { handleStateChange(1); }}>
													<h3>{text.expenses.title}</h3>
											</li>
											<li className={ stateTab.activeTab === 2 ? "active list-inline-item" : "list-inline-item" } onClick={() => { handleStateChange(2); }}>
													<h3>{text.guidelines.title}</h3>
											</li>
											<li className={ stateTab.activeTab === 3 ? "active list-inline-item" : "list-inline-item" } onClick={() => { handleStateChange(3); }}>
													<h3>{text.assistance.title}</h3>
											</li>
											<li className={ stateTab.activeTab === 4 ? "active list-inline-item" : "list-inline-item" } onClick={() => { handleStateChange(4); }}>
													<h3>{text.techfund.title}</h3>
											</li>
											<li className={ stateTab.activeTab === 4 ? "active list-inline-item" : "list-inline-item" }>
											</li>
									</ul>
                </div>
                {(() => {
                    if (stateTab.activeTab === 1) {
                        return (
                            <div>
                                <Row className="pr-2">
																		{/* targetとidを紐づかせる */}
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle1.active ? 'active' : ''}`} onClick={() => { handleToggleChange(1); }}><h4>{text.expenses.contents[0].title} <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.expenses.contents[0].text)} </p>
                                    </Col>
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle2.active ? 'active' : ''}`} onClick={() => { handleToggleChange(2); }}><h4>{text.expenses.contents[1].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.expenses.contents[1].text)} </p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    } else if (stateTab.activeTab === 2) {
                        return (
                            <div>
                                <Row className="pr-2">
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle3.active ? 'active' : ''}`} onClick={() => { handleToggleChange(3); }}><h4>{text.guidelines.contents[0].title} <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.guidelines.contents[0].text)} </p>
                                    </Col>
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle4.active ? 'active' : ''}`} onClick={() => { handleToggleChange(4); }}><h4>{text.guidelines.contents[1].title} <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.guidelines.contents[1].text)} </p>
                                    </Col>
																		<Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle5.active ? 'active' : ''}`} onClick={() => { handleToggleChange(5); }}><h4>{text.guidelines.contents[2].title} <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.guidelines.contents[2].text)} </p>
                                    </Col>
                                </Row>
                            </div>

                            )
                    } else if (stateTab.activeTab === 3) {
                        return (
                            <div>
                                <Row className="pr-2">
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle6.active ? 'active' : ''}`} onClick={() => { handleToggleChange(6); }}><h4>{text.assistance.contents[0].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.assistance.contents[0].text)}</p>
                                    </Col>
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle7.active ? 'active' : ''}`} onClick={() => { handleToggleChange(7); }}><h4>{text.assistance.contents[1].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.assistance.contents[1].text)}</p>
                                    </Col>
																		<Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle8.active ? 'active' : ''}`} onClick={() => { handleToggleChange(8); }}><h4>{text.assistance.contents[2].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.assistance.contents[2].text)}</p>
                                    </Col>
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle9.active ? 'active' : ''}`} onClick={() => { handleToggleChange(9); }}><h4>{text.assistance.contents[3].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.assistance.contents[3].text)}</p>
                                    </Col>
																		<Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle10.active ? 'active' : ''}`} onClick={() => { handleToggleChange(10); }}><h4>{text.assistance.contents[4].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.assistance.contents[4].text)}</p>
                                    </Col>
                                </Row>
                            </div>

                            )
                    } else if (stateTab.activeTab === 4) {
                        return (
													<div>
                                <Row className="pr-2">
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle11.active ? 'active' : ''}`} onClick={() => { handleToggleChange(11); }}><h4>{text.techfund.contents[0].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.techfund.contents[0].text)}</p>
                                    </Col>
                                    <Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle12.active ? 'active' : ''}`} onClick={() => { handleToggleChange(12); }}><h4>{text.techfund.contents[1].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.techfund.contents[1].text)}</p>
                                    </Col>
																		<Col className="list__info mt-5 mb-2" md="12" xs="12">
																				<a className={`block-toggle ${stateToggle13.active ? 'active' : ''}`} onClick={() => { handleToggleChange(13); }}><h4>{text.techfund.contents[2].title}  <span className="btn-show"></span></h4></a>
                                        <p className="toggle-content">{checkText(text.techfund.contents[2].text)}</p>
                                    </Col>
                                </Row>
                        </div>)
                    }
                })()}
							</Col>
						</Row>
					</div>
				</Container>
			</section>
  );
};

export default Faq;