import React from "react";
import Helmet from "react-helmet";
// require('bootstrap');
import "../assets/bootstrap.min.css";
import "../assets/index.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = ({ lang, children, location }) => {
  return (
    <div className="wrap_ap">
      <Helmet
        title="ACCEL PROGRAM | TECHFUND"
        meta={[
          { name: "description", content: "ACCEL PROGRAMは、技術によって新規事業を支援するアクセラレータープログラムです。ビジネス・開発・マーケティングなど、新規事業を行う際に必要となるサポートをご用意しております。" },
          { name: "keywords", content: "Accelerator, Acceleration Program, ICO, IEO, STO, Smart Contract Development, Smart Contract Audit, TECHFUND, ACCEL PROGRAM" },
          { property: 'og:title', content: "ACCEL PROGRAM | TECHFUND" },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: 'https://accel-program.io/' },
          { property: 'og:image', content: 'https://accel-program.io/assets/images/ogp.jpg' },
          { property: 'og:description', content: "ACCEL PROGRAMは、新規事業を技術によって支援するアクセラレータープログラムです。ビジネス・開発・マーケティングなど、新規事業を行う際に必要となるサポートをご用意しております。" }
        ]}
      />
      <Header lang={lang} />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
