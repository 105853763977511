import React from "react";

function ProgramDetailList() {
  return (
    <div id="program-detail" className="section_program_detail">
      <h1>
        INCUBATION &<br />
        ACCELERATION
        <br className="sp_only" /> PROGRAM
      </h1>
      <p className="subtitle">
      社内起業家たちが事業を立ち上げるために必要なノウハウを研修を通して学び、<br className="pc-only"/>当社と一緒に設計から運用までご依頼いただけます。<br />また、プログラムを通して生まれたアイデアを事業化するまで支援いたします。
      </p>
      <div className="list_block">
        <h3>DESIGN</h3>
        <p className="desc">
          このフェーズでは、最終的に社内に新規事業エコシステムを構築することを目指し、まず戦略や制度・インフラを整えます。会社ごとに必要な設計は変わるため、当社のプログラムデザイナーが設計のサポートをいたします。
        </p>
        <div className="content_block">
          <h4>
            <img className="icon_01" src="/assets/images/forbiz/icon_01.svg" />
            戦略立案
          </h4>
          <div className="detail_block">
            <p>
              会社に最も適したプログラムはどのようなものかを検討し、実行するための戦略を立てます。
              <br />
              既存のプログラムでもステージゲート方式のインキュベーションプログラム、出島、オープンイノベーションなど、複数の選択肢があります。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_01.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_02" src="/assets/images/forbiz/icon_02.svg" />
            制度設計
          </h4>
          <div className="detail_block">
            <p>
              人事制度やその垣根を超えた制度の設計・導入を支援します。例えば、社内起業家のモチベーションを長期維持するための「無利子無期限融資・子会社株購入可能制度」など、複雑な制度の設計もサポートいたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_02.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_03" src="/assets/images/forbiz/icon_03.svg" />
            システム導入
          </h4>
          <div className="detail_block_type_2">
            <p>
              プログラムを手運用すると運営が回らなくなります。この効率化課題をクリアするために当社独自のプログラム運営システム「ACCEL
              INTRA」をOEM提供いたします。
              イントレプレナーの集客、イントレプレナーのビジョンやスキル把握、チームマッチング、ワークショップイベント通知ま
              で、オールインで提供できます。TECHFUNDのアクセラレータープログラムでも起業家の情報把握やイベント告知のために使用していたシステムです。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_03.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="list_block">
        <h3>OPERATION</h3>
        <p className="desc">
          このフェーズでは、社内にある多くのアイデアや優秀な人材を発掘し育成するインキュベーションプログラムを実際に運用します。プログラムから出てきたアイデアに対しては仮説検証を行い、そのアイデアが本当に事業化可能なものなのかを確かめるDemodayを開催いたします。
        </p>
        <div className="content_block">
          <h4>
            <img className="icon_04" src="/assets/images/forbiz/icon_04.svg" />
            社内広報
          </h4>
          <div className="detail_block">
            <p>
              社内インキュベーションプログラムを開始したことを社内に告知します。
              <br />
              LPやポスターなどの告知物の制作も協力いたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_04.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_05" src="/assets/images/forbiz/icon_05.svg" />
            ワークショップ
          </h4>
          <div className="detail_block">
            <p>
              社内起業家がアイデアを立案・事業化するにおいて必要となる知識のインプット・教育にご協力します。リーンスタートアップの講義からアイデアソンやハッカソンまで、社内では用意しきれないコンテンツを提供いたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_05.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_06" src="/assets/images/forbiz/icon_06.svg" />
            仮説検証
          </h4>
          <div className="detail_block_type_2">
            <p>
              ユーザーに求められるサービスを共に模索し企画提案力と開発力でPOCの可能性を最大化します。
              <br />
              当社POCコンサルタントがハンズオン形式で事業の仮説検証に寄り添い、プロダクト化前のアイデアをブラッシュアップすることにより、事業化をより確実なものにします。リーンスタートアップの手法から作成した当社独自の事業整理キャンバスを使い、社内の意向・社内起業家のビジョン・ユーザーニーズを紐づける仮説検証をサポートします。また、仮説検証の際に必要となった場合にはプロトタイプの開発も当社で支援いたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_06.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_07" src="/assets/images/forbiz/icon_07.svg" />
            Demoday
          </h4>
          <div className="detail_block">
            <p>
              どのチームの支援に注力していくべきかを運営チームが見極められない課題を当社の事業スコアリングレポートで解決いたします。これまで300を超える新規事業を見てきたデータをもとに、人材と市場の定量/定性評価を行います。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_07.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="list_block">
        <h3>REAL BUSINESS</h3>
        <p className="desc">
          このフェーズでは、社内決裁が完了した事業のプロダクト開発を行い、ユーザーに向けてリリースをしていきます。グロースを目指して必要となる機能をトータルサポートいたします。
        </p>
        <div className="content_block">
          <h4>
            <img className="icon_08" src="/assets/images/forbiz/icon_08.svg" />
            開発
          </h4>
          <div className="detail_block_type_2">
            <p>
              リーンスタートアップの手法に最も適した開発手法にて開発を行います。ウォーターフォールな開発手法だとユーザーヒアリングなどの仮説検証の結果によって作るものが変わった場合に取り返しがつかなくなるため、プロダクトオーナーにNoと言える・ピボットを許すような体制を作り開発を行います。社内ルールなどで必要な場合、細かなセキュリティリスクを洗い出し対応した上でリリースを行うことも可能です。大手証券や金融機関等の新規事業を経験してきた当社だからこその柔軟な開発で新規事業のサービス作りを支援します。Webサービスからブロックチェーンまで幅広い開発範囲をカバー可能です。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_08.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_09" src="/assets/images/forbiz/icon_09.svg" />
            マーケティング
          </h4>
          <div className="detail_block">
            <p>
              Google広告などのリスティングから、ユーザー会の開催、BizDevまで、様々な手法で事業のマーケティングを支援いたします。プロダクトのPMFから収益化まで、事業として成り立つサービスのリリースをお手伝いいたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_09.png" />
            </div>
          </div>
        </div>
        <div className="content_block">
          <h4>
            <img className="icon_10" src="/assets/images/forbiz/icon_10.svg" />
            資金調達
          </h4>
          <div className="detail_block">
            <p>
              社内で決裁しきれない事業などがある場合、社外での資金調達を行う際のサポートをします。クラウドファンディングの支援や投資家のご紹介など、最適な方法で調達を支援いたします。
            </p>
            <div>
              <img src="/assets/images/forbiz/detail_img_10.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="block_partner">
        <div className="title_block">
          <span className="tag">EXAMPLE01</span>
          {/* <h3>大手通信会社</h3> */}
          <span>ステージゲート式で設計</span>
        </div>
        <div className="content_block">
          <div>
            <p className="content_title">
              社内企業家育成プログラムを構築＆共同運用
            </p>
            <p>
            ほとんど事業化が生まれなかった社内起業プログラムを改革、年4件事業化へ。新規事業立ち上げ支援プログラムの構築と共同運営
            元々社内起業プログラムが存在していた企業にて「社内からの新規事業応募は一定数あるが事業化しない」という課題がありました。
            当社はその課題に対して、応募〜事業化までのフェーズを細かく区切って支援内容を変える「ステージゲート」と呼ばれる方式にて候補者育成の仕組みを構築。
            応募前の候補者に対してはリーンスタートアップの講義や社内起業家交流会を開き、応募者に対して仮説検証メンタリングや開発支援を行う等、共同運営を実施しました。
            また、各フェーズの審査判断や運営全体の負荷を軽減するために当社独自のプログラム運営システム「ACCEL INTRA」を導入し事務局コストを40%以上カットすることもできました。
            この企業では結果として応募数が年間約800件まで伸び、そのうち事業化検討に至ったチームが約50件、事業化に至ったプロダクトが4件生まれました。     </p>
            {/* <img
              className="img_partner_detail"
              src="/assets/images/forbiz/partner_detail.png"
            /> */}
          </div>
          <div>
            <img
              className="img_partner_thumb"
              src="/assets/images/forbiz/partner_01.png"
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="section_join">
        <h3>JOIN US!</h3>
        <p>
        多数のプログラム設計・運営経験を元に貴社に最適なプログラムを構築します。TECHFUNDが持つアクセラレーターとしての知見と、貴社のアセットと組織の力を掛け合わせて、これまでにないイノベーティブなプログラムを推進しましょう。当社が全力でサポートいたします！
        </p>
        <a href="/forbiz/request" target="_blank" className="btn_ask">
          {/* <span>
            <img src="/assets/images/forbiz/icon_arrow_white.svg" />
            _資料請求
          </span> */}
          <img src="/assets/images/forbiz/btn_ask_yellow.svg" />
        </a>
      </div>
    </div>
  );
}

export default ProgramDetailList;
