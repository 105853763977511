import React from 'react'
import Layout from '../../layouts/';
import ContactForm from '../../components/ContactForm';
const ContactPage = () => (
  <Layout lang="en">
		<ContactForm lang="en"/>
  </Layout>
)

export default ContactPage

