import React from 'react'
import Layout from '../layouts/';
import ApplyComplete from '../components/ApplyComplete';
const ApplyCompletePage = () => (
  <Layout lang="日本語">
		<ApplyComplete lang="日本語"/>
  </Layout>
)

export default ApplyCompletePage

