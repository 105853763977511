import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
} from "reactstrap";
import team from "../data/team.json";
import partner from "../data/partner.json";
const Team = (props) => {
  const lang = props.lang;
  const [textTeam, setText] = useState(team[lang]);
  const [textPartner, setPartner] = useState(partner[lang]);
  return (
    <Fragment>
      <section className="container-primary container-team">
        <Container>
          <div className="content-wrap">
            <Row className="pt-4">
              <Col xs="12">
                <h3>TECHFUND</h3>
                <p>{textTeam.text}</p>
              </Col>
            </Row>
            <Row className="mt-5">
              {textTeam.members.map((list) => {
                return (
                  <Col sm="3" xs="6">
                    <Card>
                      <CardImg
                        className="thumb-round"
                        top
                        width="100%"
                        src={list.img}
                        alt="Card image cap"
                      />
                      <CardBody>
                        <h4>{list.name}</h4>
                        <CardText className="role">{list.role}</CardText>
                        <CardText className="role_title">
                          {list.role_title}
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </section>
      <section className="container-dark container-partner">
        <Container>
          <div className="content-wrap">
            <Row className="pt-4">
              <Col xs="12">
                <h3>PARTNER</h3>
                <p>{textPartner.text}</p>
              </Col>
            </Row>
            <Row className="mt-5">
              {textPartner.companies
                .filter((v, i) => i < 3)
                .map((list) => {
                  return (
                    <Col sm="2" xs="4">
                      <Card>
                        <CardImg
                          className="thumb-round"
                          top
                          width="100%"
                          src={list.img}
                          alt="Card image cap"
                        />
                        <h4>{list.title}</h4>
                      </Card>
                    </Col>
                  );
                })}
              {textPartner.peoples
                .filter((v, i) => i < 3)
                .map((list) => {
                  return (
                    <Col sm="2" xs="4">
                      <Card>
                        <CardImg
                          className="thumb-round"
                          top
                          width="100%"
                          src={list.img}
                          alt="Card image cap"
                        />
                        <h4>{list.title}</h4>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            <Row className="mt-4 mb-5">
              <Link
                to={props.lang === "日本語" ? "/partner/" : "/en/partner/"}
                className="btn-primary btn-more"
              >
                Read in details
              </Link>
            </Row>
          </div>
        </Container>
      </section>
    </Fragment>
  );
};

export default Team;
