import React from "react";
import Layout from "../../layouts/forbizTop";
import Kv from "../../components/forbiz/Kv";
import WhatWeDo from "../../components/forbiz/WhatWeDo";
import Strengthes from "../../components/forbiz/Strengthes";
import Program from "../../components/forbiz/Program";
import Topics from "../../components/forbiz/Topics";
import Member from "../../components/forbiz/Member";
import Portfolio from "../../components/forbiz/Portfolio";
import Flow from "../../components/forbiz/Flow";
import About from "../../components/forbiz/About";
import Contact from "../../components/forbiz/Contact";
function index() {
  return (
    <Layout>
      <Kv />
      <WhatWeDo />
      <Strengthes more={true} />
      <Program />
      <Topics />
      <Member />
      <Flow />
      <About />
      <Contact />
    </Layout>
  );
}

export default index;
