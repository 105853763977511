import React from 'react'
import Layout from '../layouts/';
import Partner from '../components/Partner';
import Contact from '../components/Contact';
const PartnerPage = () => (
  <Layout lang="日本語">
    <Partner lang="日本語"/>
		<Contact lang="日本語"/>
  </Layout>
)

export default PartnerPage
