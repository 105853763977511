import React from 'react'
import Layout from '../../layouts/';
import ContactConfirm from '../../components/ContactConfirm';
const ContactConfirmPage = () => (
  <Layout lang="en">
		<ContactConfirm lang="en"/>
  </Layout>
)

export default ContactConfirmPage

