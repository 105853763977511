import React, { useEffect } from "react";
import Helmet from "react-helmet";
// require('bootstrap');
import "../assets/forbiz.css";
import Header from "../components/forbiz/Header";
import HeaderSp from "../components/forbiz/HeaderSp";
import Sidenav from "../components/forbiz/Sidenav";
import Contentsnav from "../components/forbiz/Contentsnav";
import Footer from "../components/forbiz/Footer";
import Member from "../components/forbiz/Member";
const Layout = ({ lang, children, location }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="wrap_forbiz">
      <Helmet
        title="ACCEL PROGRAM for BIZ | スタートアップスタジオ支援"
        meta={[
          {
            name: "description",
            content:
              "ACCEL PROGRAM for BIZは、技術によって新規事業を支援するアクセラレータープログラムです。ビジネス・開発・マーケティングなど、新規事業を行う際に必要となるサポートをご用意しております。",
          },
          {
            name: "keywords",
            content:
              "Accelerator, Acceleration Program, スタートアップスタジオ, Blockchain, Smart Contract Development, Smart Contract Audit, TECHFUND, ACCEL PROGRAM",
          },
          { property: "og:title", content: "ACCEL PROGRAM for BIZ | スタートアップスタジオ支援" },
          { property: "og:type", content: "website" },
          { property: "og:url", content: "https://accel-program.io/forbiz/" },
          {
            property: "og:image",
            content: "https://accel-program.io/assets/images/ogp.jpg",
          },
          {
            property: "og:description",
            content:
              "ACCEL PROGRAM for BIZは、新規事業を技術によって支援するアクセラレータープログラムです。ビジネス・開発・マーケティングなど、新規事業を行う際に必要となるサポートをご用意しております。",
          },
        ]}
      />
      <Sidenav />
      <HeaderSp />
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
