import React, { useState, useCallback, useEffect } from "react";
import members from "../../data/forbiz/member.json";

function Member() {
  const [activeMember, setActiveMember] = useState(1);
  const [chunkedMember, setChunkedMember] = useState([])
  const handleSetActiveMember = useCallback((n) => {
    setActiveMember(n);
  }, []);
  const handleMove = useCallback(
    (n) => {
      let num = activeMember + n;
      if (num === 0) {
        num = 4;
      } else if (num === 5) {
        num = 1;
      }
      setActiveMember(num);
    },
    [activeMember]
  );

  const chunk = useCallback((array, size) => {
    const chunked = [];
    let index = 0;

    while (index < array.length) {
        chunked.push(array.slice(index, index + size));
        index += size;
    }

    return chunked;
  }, [])

  useEffect(() => {
    setChunkedMember(chunk(members.members, 4))
  }, [])

  return (
    <div id="member" className="section_member">
      {activeMember === 1 && (
        <>
        {
          chunkedMember[0]?.map((m, i) => (
            <div className="member_block" key={i}>
              <div className="thumb_cover">
              <img
                className="thumb_active"
                src={m.active_img}
              />
                <img
                  className="thumb"
                  src={m.img}
                />
              </div>
              <span className="role">{m.role}</span>
              <h3>{m.name}</h3>
              <p className="desc">
                {m.desc}
              </p>
            </div>
          ))
        }
        </>
      )}
      {activeMember === 2 && (
        <>
        {
          chunkedMember[1]?.map((m, i) => (
            <div className="member_block" key={i}>
              <div className="thumb_cover">
              <img
                className="thumb_active"
                src={m.active_img}
              />
                <img
                  className="thumb"
                  src={m.img}
                />
              </div>
              <span className="role">{m.role}</span>
              <h3>{m.name}</h3>
              <p className="desc">
                {m.desc}
              </p>
            </div>
          ))
        }
        </>
      )}
      {activeMember === 3 && (
        <>
        {
          chunkedMember[2]?.map((m, i) => (
            <div className="member_block" key={i}>
              <div className="thumb_cover">
              <img
                className="thumb_active"
                src={m.active_img}
              />
                <img
                  className="thumb"
                  src={m.img}
                />
              </div>
              <span className="role">{m.role}</span>
              <h3>{m.name}</h3>
              <p className="desc">
                {m.desc}
              </p>
            </div>
          ))
        }
        </>
      )}
      {activeMember === 4 && (
        <>
        {
          chunkedMember[3]?.map((m, i) => (
            <div className="member_block" key={i}>
              <div className="thumb_cover">
              <img
                className="thumb_active"
                src={m.active_img}
              />
                <img
                  className="thumb"
                  src={m.img}
                />
              </div>
              <span className="role">{m.role}</span>
              <h3>{m.name}</h3>
              <p className="desc">
                {m.desc}
              </p>
            </div>
          ))
        }
        </>
      )}
      <div className="member_nav">
        <a
          onClick={() => handleSetActiveMember(1)}
          className={`link_block pc_only ${activeMember === 1 ? "active" : ""}`}
        >
          <div className="thumb_list">
            <>
              {
                chunkedMember[0]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <p className="page_num">PAGE 1</p>
        </a>
        <a
          onClick={() => handleSetActiveMember(2)}
          className={`link_block pc_only ${activeMember === 2 ? "active" : ""}`}
        >
          <div className="thumb_list">
            <>
              {
                chunkedMember[1]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <p className="page_num">PAGE 2</p>
        </a>
        <a
          onClick={() => handleSetActiveMember(3)}
          className={`link_block pc_only ${activeMember === 3 ? "active" : ""}`}
        >
          <div className="thumb_list">
            <>
              {
                chunkedMember[2]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <p className="page_num">PAGE 3</p>
        </a>
        <a
          onClick={() => handleSetActiveMember(4)}
          className={`link_block pc_only ${activeMember === 4 ? "active" : ""}`}
        >
          <div className="thumb_list">
            <>
              {
                chunkedMember[3]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <p className="page_num">PAGE 4</p>
        </a>
      </div>
      <div className="member_nav_sp">
        <a className="a_left" onClick={() => handleMove(-1)}>
          <img src="/assets/images/forbiz/arrow_left.svg" />
        </a>
        <a className="a_right" onClick={() => handleMove(1)}>
          <img src="/assets/images/forbiz/arrow_right.svg" />
        </a>
        <div className={`thumb_list_sp ${activeMember === 1 ? "active" : ""}`}>
            <>
              {
                chunkedMember[0]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <div className={`thumb_list_sp ${activeMember === 2 ? "active" : ""}`}>
            <>
              {
                chunkedMember[1]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <div className={`thumb_list_sp ${activeMember === 3 ? "active" : ""}`}>
            <>
              {
                chunkedMember[2]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
          <div className={`thumb_list_sp ${activeMember === 4 ? "active" : ""}`}>
            <>
              {
                chunkedMember[3]?.map((m, i) => (
                    <img src={m.img} key={i} />

                ))
              }
            </>
          </div>
      </div>
    </div>
  );
}

export default Member;
