import React from 'react'
import Layout from '../../layouts/';
import Kv from '../../components/Kv';
import Feature from '../../components/Feature';
import Support from '../../components/Support';
import Startup from '../../components/Startup';
import Team from '../../components/Team';
import Faq from '../../components/Faq';
import News from '../../components/News';
import Contact from '../../components/Contact';

const IndexPage = () => (
  <Layout lang="en">
    <Kv />
    <Feature lang="en"/>
    <Support lang="en"/>
    <Startup lang="en"/>
    <Team lang="en"/>
    <Faq lang="en"/>
    {/* <News lang="en"/> */}
    <Contact lang="en"/>

    {/* <Link to="/partner/">Go to page 2</Link>3 */}
  </Layout>
)

export default IndexPage
