import React from 'react'
import Layout from '../layouts/';
import Startups from '../components/Startups';
import Contact from '../components/Contact';
const StartupPage = () => (
  <Layout lang="日本語">
    <Startups lang="日本語"/>
		<Contact lang="日本語"/>
  </Layout>
)

export default StartupPage
