import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import data from "../data/contact.json";
import moment from "moment-timezone";

import request from "request";

var MY_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
var slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);
slack.onError = function(err) {
  console.log("API error:", err);
};

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      created_at: moment()
        .tz("Asia/Tokyo")
        .format("YYYY-MM-DD HH:mm:ss"),
      projectname: "",
      name: "",
      email: "",
      status: "",
      plan: "",
      more: "",
    };
  }

  updateState(e) {
    // 入力内容に合わせてstateを更新
    var v = this.state;
    v[e.target.name] = e.target.value;
    this.setState(v);
  }

  onConfirm(e) {
    var scope = this;
    try {
      this.formValidation();
      var options = {
        uri: "https://accel-baas.firebaseio.com/program.json",
        headers: {
          "Content-type": "application/json",
        },
        json: this.state,
      };
      request.post(options, function(error, response, body) {
        var options2 = {
          uri:
            "https://us-central1-accel-baas.cloudfunctions.net/accelProgramSendThanksMail",
          headers: {
            "Content-type": "application/json",
          },
          json: {
            data: {
              name: scope.state.name,
              destination: scope.state.email,
            },
          },
        };
        request.post(options2, function(error, response, body) {
          slack.success(
            "[AP_TO]起業家から応募がありました！" +
              "\nName:" +
              scope.state.name +
              "\nProject Name:" +
              scope.state.projectname +
              "\nemail:" +
              scope.state.email +
              "\nstatus:" +
              scope.state.status +
              "\nplan:" +
              scope.state.plan +
              "\nmore:" +
              scope.state.more
          );
          window.location.replace("/apply-complete/");
          // notify.show('エントリーが完了しました！数日以内に担当よりご連絡させていただきます。', "success");
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  formValidation() {
    if (!this.state.projectname || !this.state.name || !this.state.email)
      throw new Error("error");
  }

  render() {
    const text = data[this.props.lang];
    return (
      <section id="apply" className="container-contact">
        <Container>
          <div className="content-wrap">
            <h2>Let's join, too!</h2>
            <Row>
              <Col sm="12" xs="12">
                <Card>
                  <CardBody>
                    <h3>
                      APPLY <span> 2020 BATCH #5</span>
                    </h3>
                    <hr />
                    <Form className="form">
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="projectName">
                              Project name<span>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="projectname"
                              id="projectName"
                              value={this.state.projectname}
                              placeholder={text.f1.placeholder}
                              onChange={(e) => {
                                this.updateState(e);
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="repreName">
                              Representative's name<span>*</span>
                            </Label>
                            <Input
                              type="text"
                              name="name"
                              id="repreName"
                              value={this.state.name}
                              placeholder={text.f4.placeholder}
                              onChange={(e) => {
                                this.updateState(e);
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">
                              E-mail<span>*</span>
                            </Label>
                            <Input
                              type="email"
                              name="email"
                              id="email"
                              value={this.state.email}
                              placeholder={text.f5.placeholder}
                              onChange={(e) => {
                                this.updateState(e);
                              }}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="status">Status</Label>
                            <Input
                              type="select"
                              name="status"
                              id="status"
                              value={this.state.status}
                              onChange={(e) => {
                                this.updateState(e);
                              }}
                            >
                              <option value="0"> {text.f6.placeholder}</option>
                              <option value="1">{text.f6.options[0]}</option>
                              <option value="2">{text.f6.options[1]}</option>
                              <option value="3">{text.f6.options[2]}</option>
                              <option value="4">{text.f6.options[3]}</option>
                              <option value="5">{text.f6.options[4]}</option>
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label for="plan">Plan</Label>
                        <Input
                          type="textarea"
                          name="plan"
                          id="plan"
                          placeholder={text.f2.placeholder}
                          value={this.state.plan}
                          onChange={(e) => {
                            this.updateState(e);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="more">And more</Label>
                        <Input
                          type="textarea"
                          name="more"
                          id="more"
                          placeholder={text.f3.placeholder}
                          value={this.state.more}
                          onChange={(e) => {
                            this.updateState(e);
                          }}
                        />
                      </FormGroup>

                      <Button
                        className="btn-primary btn-submit"
                        onClick={(e) => {
                          this.onConfirm(e);
                        }}
                      >
                        APPLY NOW!
                      </Button>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    );
  }
}
