import React from "react";
import Layout from "../../layouts/forbiz";
import Contact from "../../components/forbiz/ContactLPThanks";
function Request() {
  if (window.location.pathname.length >= 17) {
    if ("/forbiz/lp/thanks" === window.location.pathname.substr(0, 17)) {
      console.log("thanks");
      // GA
      let script1 = document.createElement('script');
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10837794128';
      script1.async = true;
      document.body.appendChild(script1);
     
      let script2 = document.createElement('script');
      // プレーンテキスト、グローバルな window.gtag 関数を定義するため
      script2.text = `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-10837794128');`;
      document.body.appendChild(script2);
    }
  }
  return (
    <Layout>
      <div className="page_request">
        <Contact />
      </div>
    </Layout>
  );
}

export default Request;
