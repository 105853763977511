import React from 'react'
import Layout from '../layouts/';
import ContactConfirm from '../components/ContactConfirm';
const ContactConfirmPage = () => (
  <Layout lang="日本語">
		<ContactConfirm lang="日本語"/>
  </Layout>
)

export default ContactConfirmPage

