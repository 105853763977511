import React from "react";
import { Link } from "react-router-dom";
function Header() {
  return (
    <div className="header pc_only">
      <ul>
        <li>
          <Link to="/forbiz/program">/ PROGRAMS</Link>
        </li>
        <li>
          <Link to="/forbiz/topics">/ TOPICS</Link>
        </li>
        <li>
          <Link className="btn_request" to="/forbiz/request">
            資料請求
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Header;
