import React from 'react'
import Layout from '../../layouts/';
import Partner from '../../components/Partner';
import Contact from '../../components/Contact';
const PartnerPage = () => (
  <Layout lang="en">
    <Partner lang="en"/>
		<Contact lang="en"/>
  </Layout>
)

export default PartnerPage
