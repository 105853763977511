import React from "react";
import Layout from "../../layouts/forbiz";
import topics from "../../data/forbiz/topics.json";
import { Link } from "react-router-dom";
function Topics() {
  return (
    <Layout>
      <div className="page_topics">
        <div className="wrap_topics">
          <div className="topic_header">
            <h1>TOPICS</h1>
            {/*<div className="list_topics">
              <span className="active">ALL</span>
              <span>PROGRAMS</span>
              <span>EVENTS</span>
              <span>NEWS</span>
            </div>*/}
          </div>
          <div className="topic_body">
            {topics.topics.map((t, i) => (
              <Link to={`/forbiz/topics/${t.id}`}>
                <div className="topic_block" key={i}>
                  <img className="thumb" src={t.img} />
                  <img className="thumb_active" src={t.active_img} />
                  <div className="text_block">
                    <h4>{t.title}</h4>
                    <div className="tag">
                      {t.tags.map((tag) => (
                        <span>{tag}</span>
                      ))}
                    </div>
                    <p className="desc">{t.desc}</p>
                      <img
                        className="icon_arrow"
                        src="/assets/images/forbiz/icon_arrow_yellow.svg"
                      />
                      <span className="f_yellow"> _</span>もっと読む
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Topics;
