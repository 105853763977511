import React from "react";
import { Link } from "react-router-dom";
function Sidenav() {
  return (
    <div className="sidenav pc_only">
      <Link to="/forbiz/">
        <img className="logo" src="/assets/images/forbiz/Logo.png" />
      </Link>
      <img className="f_tf" src="/assets/images/forbiz/f_techfund.png" />
      {/* <img className="f_nav" src="/assets/images/forbiz/f_welcome.svg" /> */}
    </div>
  );
}

export default Sidenav;
