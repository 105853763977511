import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
//import CommentsContext from '../context/comments';

import request from "request";
import moment from "moment-timezone";

export default class News extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    var scope = this;
    try {
      var options = {
        uri: "https://accel-baas.firebaseio.com/articles.json",
      };
      request.get(options, function(error, response, body) {
        var articles = JSON.parse(response.body);
        for (let i in articles) {
          let old_articles = scope.state.articles;
          old_articles.push(articles[i]);
          scope.setState({ articles: old_articles });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  link(uri) {
    window.location.href = "/content-details/" + uri;
  }

  render() {
    const list = this.state.articles;
    return (
      <section id="content" className="container-primary container-news">
        <Container>
          <h2>News/Articles</h2>
          <div className="content-wrap">
            {list
              .filter((v, i) => i < 3)
              .map((v) => {
                let body_txt = v.body
                  .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
                  .slice(0, 250);
                if (250 < v.body.length) {
                  body_txt += "...";
                }
                return (
                  <Card
                    className="mb-4"
                    onClick={(e) => {
                      this.link(v.uri);
                    }}
                  >
                    <Row>
                      <Col sm="4" xs="12">
                        <div className="image-wrap">
                          <img className="thumb-100" src={v.thumbnail} />
                          <span className="tag tag-article">Article</span>
                        </div>
                      </Col>
                      <Col sm="8" xs="12">
                        <h3>{v.title}</h3>
                        <span className="date">
                          {moment(v.created_at)
                            .tz("Asia/Tokyo")
                            .format("MMMM Do, YYYY")}
                        </span>
                        <p className="mt-2">{body_txt}</p>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
          </div>
          <Link className="btn btn-dark btn-more" to="/contents/">
            read more
          </Link>
        </Container>
      </section>
    );
  }
}
