import React from "react";
import Layout from "../../layouts/forbiz";
import ProgramDetailList from "../../components/forbiz/ProgramDetailList";
import Strengthes from "../../components/forbiz/Strengthes";
import Program from "../../components/forbiz/Program";
import Topics from "../../components/forbiz/Topics";
import Member from "../../components/forbiz/Member";
import Portfolio from "../../components/forbiz/Portfolio";
import Flow from "../../components/forbiz/Flow";
import About from "../../components/forbiz/About";
import Contact from "../../components/forbiz/Contact";
function ProgramDetail() {
  return (
    <Layout>
      <div className="section_detail_top">
        <div className="wrap_contents">
          <div className="block_contents">
            <h1>PROGRAMS</h1>
            <p className="subtitle">
              新規事業の確実な成功に向けた、
              <br className="sp_only" />
              手応えを掴むプログラム
            </p>
            <p className="desc">
              多くの実績を元に様々な角度から最適な組織設計・事業創出を支援します。
              <br />
              社内にある多くのアイデアや優秀な人材を発掘し育成するインキュベーションプログラムから、ユーザーに求められるサービスを共に模索し企画提案力と開発力でPOCの可能性を最大化する事業立ち上げ支援までをトータルサポートします。
            </p>
          </div>

          <div className="block_contents">
            <h2 className="title_exp">EXPERIENCE</h2>
            <div className="block_exp">
              <img src="/assets/images/forbiz/logo/nomura.png" />
              <img src="/assets/images/forbiz/logo/softbank.png" />
              <img src="/assets/images/forbiz/logo/uzabase.png" />
              <img src="/assets/images/forbiz/logo/jcb.png" />
              <img src="/assets/images/forbiz/logo/tokyodome.png" />
              <img src="/assets/images/forbiz/logo/nec.png" />
              <img src="/assets/images/forbiz/logo/mitsubishiestate.png" />
              <img src="/assets/images/forbiz/logo/daimarumatsuzakaya.png" />
              <img src="/assets/images/forbiz/logo/etc.png" />
            </div>
          </div>
        </div>
      </div>
      <div className="wrap_stre">
        <Strengthes />
      </div>

      <ProgramDetailList />
    </Layout>
  );
}

export default ProgramDetail;
