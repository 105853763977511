import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card, CardBody,
	Button} from 'reactstrap';

const ContactConfirm = () => {
  return (
			<Fragment>
			<section className="container-header container-startups">
				<Container>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">TOP</a></li>
								<li className="breadcrumb-item" aria-current="page">CONTACT</li>
							</ol>
						<h2>CONTACT</h2>
				</Container>
			</section>
      <section className="container-contact-confirn">
				<Container>
					<div className="content-wrap">
						<h2>内容をご確認ください</h2>
						<hr />
						<Row>
							<Col sm={{size:10, offset:1}} xs="12">
								<Card>
										<CardBody>
											<Row>
												<Col md={5}>
													<p className="label">Name</p>
												</Col>
												<Col md={7}>
													<p className="form-value">name</p>
												</Col>
											</Row>
											<Row>
												<Col md={5}>
													<p className="label">E - mail</p>
												</Col>
												<Col md={7}>
													<p className="form-value">mayu@gmail.com</p>
												</Col>
											</Row>
											<Row>
												<Col md={5}>
													<p className="label">Type</p>
												</Col>
												<Col md={7}>
													<p className="form-value">text</p>
												</Col>
											</Row>
											<Row>
												<Col md={12}>
													<p className="label no-border">And more</p>
													<p>テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。テキストが入ります。</p>
												</Col>
											</Row>

											<Link className="back" to="/contact-complete/"><Button className="btn-primary btn-submit">OK</Button></Link>
												<Link className="back" to="/">戻って修正する</Link>
										</CardBody>
									</Card>
							</Col>
						</Row>

					</div>
				</Container>
			</section>
			</Fragment>
  );
};

export default ContactConfirm;
