import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card } from 'reactstrap';
	import data from '../data/partner.json';
	import {checkText} from '../utils/common.js';
const Partner = (props) => {
	const [text, setText] = useState(data[props.lang]);
  return (
			<Fragment>
			<section className="container-header container-partner-header">
				<Container>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">TOP</a></li>
								<li className="breadcrumb-item" aria-current="page">Partner</li>
							</ol>
						<h2>Partner</h2>
				</Container>
			</section>
      <section className="container-baige container-su">
				<Container>
					<div className="content-wrap">
						<Row>
							<Col sm="12" xs="12">
								<p>{text.text}</p>
							</Col>
						</Row>
						<hr />
		                    {text.peoples.map((list) => {
		                        return (
									<Card className="mb-4">
										<Row>
											<Col sm="3" xs="4">
												<div className="image-wrap">
													<img className="thumb-round-160" src={list.img} />
												</div>
											</Col>
											<Col sm="9" xs="8">
												<h3>{list.title}</h3>
												<p className="mt-2 mb-5">
												{checkText(list.text)}
												</p>
											</Col>
										</Row>
									</Card>
		                        )
		                    })}
						<Row className="mt-10 pc-only">
		                    {text.companies.map((list) => {
		                        return (
									<Col sm="4" xs="12">
										<img className="thumb-100 mb-3" src={list.img} />
									</Col>
		                        )
		                    })}
						</Row>
					</div>
				</Container>
			</section>
			</Fragment>
  );
};

export default Partner;