import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../components/Header';
import Edit from '../components/Edit';
import Footer from '../components/Footer';

class Article extends Component {
  state = {
    lang: '',
    text: {},
    article_id: 0
  };
  handleChangeLanguage = async (val) => {
    await this.setState({
      lang: val
    })
  }

  componentWillMount() {
    const locale = Intl.NumberFormat().resolvedOptions().locale
    if (locale.match(/ja/)) {
      this.setState({
        lang: '日本語'
      })
    } else if(locale.match(/zh/)) {
      this.setState({
        lang: '中文'
      })
    } else {
      this.setState({
        lang: 'En'
      })
    }
  }

  render() {
      return (
        <div className="lp">
          <MetaTags>
            <title>ACCEL PROGRAM | TECHFUND</title>
          </MetaTags>
          <Header 
          handleChangeLanguage={this.handleChangeLanguage}
          lang={this.state.lang}
          ></Header>
          <Edit></Edit>
          <Footer></Footer>
        </div>
      );
    }
}
export default Article;
