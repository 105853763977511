import React from "react";

function Kv() {
  return (
    <div id="kv">
      <div className="kv_wrap_forbiz">
        <div className="movie-wrapper">
          <video
            className="movie"
            src="/assets/movies/bg/forbiz_kv.mp4"
            preload="auto"
            playsinline="true"
            autoplay="true"
            loop="true"
            muted="true"
            poster="/assets/images/bg/tf-back-pc.png"
          />
        </div>
        <div className="title_wrap">
          <h1>
            ACCEL PROGRAM
            <br />
            FOR BIZ
          </h1>
          <p className="subtitle">
            新規事業を興す全ての人々を、テクノロジーで支援する
          </p>
          <a href="#" className="btn_ask">
            <img src="/assets/images/forbiz/btn_ask_yellow.svg" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Kv;
