import React from "react";
import { Router } from "react-router-dom";
import { Row, Container, Col } from "reactstrap";
import request from "request";
import Notifications, { notify } from "react-notify-toast";
import moment from "moment-timezone";
import { Editor, EditorState } from "draft-js";

export default class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: "",
      articles: [],
      newarticle: {
        created_at: moment()
          .tz("Asia/Tokyo")
          .format("YYYY-MM-DD HH:mm:ss"),
        tag: "",
        title: "",
        body: "",
        uri: "",
        thumbnail: "",
        meta_title: "",
        keywords: "",
        description: "",
      },
      editorState: EditorState.createEmpty(),
    };
    this.onChange = (editorState) => this.setState({ editorState });
  }

  componentDidMount() {
    // 記事取得
    var scope = this;
    try {
      var options = {
        uri: "https://accel-baas.firebaseio.com/articles.json",
      };
      request.get(options, function(error, response, body) {
        var articles = JSON.parse(response.body);
        scope.setState({ articles: articles });

        scope.setState({
          rows: Object.keys(scope.state.articles).map((k, i) => (
            <div>
              <div>
                <input
                  type="text"
                  name={"id" + k}
                  value={k}
                  onChange={scope.update}
                  placeholder="id"
                />
              </div>
              <h1>
                <input
                  type="text"
                  name={"uri" + k}
                  value={articles[k].uri}
                  onChange={scope.update}
                  placeholder="URI"
                />
                <input
                  type="text"
                  name={"tag" + k}
                  value={articles[k].tag}
                  onChange={scope.update}
                  placeholder="tag"
                />
                <input
                  type="text"
                  name={"created_at" + k}
                  value={articles[k].created_at}
                  onChange={scope.update}
                  placeholder="公開日時"
                />
                <br />
                <input
                  type="text"
                  name={"thumbnail" + k}
                  value={articles[k].thumbnail}
                  onChange={scope.update}
                  placeholder="サムネURL"
                />
                <input
                  type="text"
                  name={"meta_title" + k}
                  value={articles[k].meta_title}
                  onChange={scope.update}
                  placeholder="meta title"
                />
                <input
                  type="text"
                  name={"keywords" + k}
                  value={articles[k].keywords}
                  onChange={scope.update}
                  placeholder="meta keywords"
                />
                <input
                  type="text"
                  name={"description" + k}
                  value={articles[k].description}
                  onChange={scope.update}
                  placeholder="meta description"
                />
              </h1>
              <h2>
                <input
                  type="text"
                  name={"title" + k}
                  value={articles[k].title}
                  onChange={scope.update}
                  placeholder="title"
                />
              </h2>
              <p>
                <textarea
                  name={"body" + k}
                  value={articles[k].body}
                  placeholder="body"
                  onChange={scope.update}
                />
              </p>
            </div>
          )),
        });
      });
    } catch (e) {
      console.log(e);
    }
  }

  // 表示
  renderList() {
    return <div>{this.state.rows}</div>;
  }

  // 記事更新
  update = (e) => {
    var articles = this.state.articles;

    for (var article in articles) {
      for (var v in articles[article]) {
        let keyname = v + article;
        if (keyname == e.target.name) {
          articles[article][v] = e.target.value;
        } else if ("id" + article == e.target.name) {
          //配列を削除して追加
          // DEBUG
        }
      }
    }
    // 再表示
    this.setState({ articles: articles });
    this.setState({
      rows: Object.keys(this.state.articles).map((k, i) => (
        <div>
          <div>
            <input
              type="text"
              name={"id" + k}
              value={k}
              onChange={this.update}
              placeholder="id"
            />
          </div>
          <h1>
            <input
              type="text"
              name={"uri" + k}
              value={articles[k].uri}
              onChange={this.update}
              placeholder="URI"
            />
            <input
              type="text"
              name={"tag" + k}
              value={articles[k].tag}
              onChange={this.update}
              placeholder="tag"
            />
            <input
              type="text"
              name={"created_at" + k}
              value={articles[k].created_at}
              onChange={this.update}
              placeholder="公開日時"
            />
            <br />
            <input
              type="text"
              name={"thumbnail" + k}
              value={articles[k].thumbnail}
              onChange={this.update}
              placeholder="サムネURL"
            />
            <input
              type="text"
              name={"meta_title" + k}
              value={articles[k].meta_title}
              onChange={this.update}
              placeholder="meta title"
            />
            <input
              type="text"
              name={"keywords" + k}
              value={articles[k].keywords}
              onChange={this.update}
              placeholder="meta keywords"
            />
            <input
              type="text"
              name={"description" + k}
              value={articles[k].description}
              onChange={this.update}
              placeholder="meta description"
            />
          </h1>
          <h2>
            <input
              type="text"
              name={"title" + k}
              value={articles[k].title}
              onChange={this.update}
              placeholder="title"
            />
          </h2>
          <p>
            <textarea
              name={"body" + k}
              value={articles[k].body}
              placeholder="body"
              onChange={this.update}
            />
          </p>
        </div>
      )),
    });
  };

  // 新規記事
  new_created_at = (e) => {
    let v = this.state.newarticle;
    v.created_at = e.target.value;
    this.setState({ newarticle: v });
  };
  new_tag = (e) => {
    let v = this.state.newarticle;
    v.tag = e.target.value;
    this.setState({ newarticle: v });
  };
  new_title = (e) => {
    let v = this.state.newarticle;
    v.title = e.target.value;
    this.setState({ newarticle: v });
  };
  new_body = (e) => {
    let v = this.state.newarticle;
    v.body = e.target.value;
    this.setState({ newarticle: v });
  };
  new_uri = (e) => {
    let v = this.state.newarticle;
    v.uri = e.target.value;
    this.setState({ newarticle: v });
  };
  new_thumbnail = (e) => {
    let v = this.state.newarticle;
    v.thumbnail = e.target.value;
    this.setState({ newarticle: v });
  };
  new_meta_title = (e) => {
    let v = this.state.newarticle;
    v.meta_title = e.target.value;
    this.setState({ newarticle: v });
  };
  new_keywords = (e) => {
    let v = this.state.newarticle;
    v.keywords = e.target.value;
    this.setState({ newarticle: v });
  };
  new_description = (e) => {
    let v = this.state.newarticle;
    v.description = e.target.value;
    this.setState({ newarticle: v });
  };

  // 保存
  save(e) {
    // 新規登録あれば配列にpost
    if ("" != this.state.newarticle.title || "" != this.state.newarticle.body) {
      console.log(this.state.newarticle);
      try {
        var options = {
          uri: "https://accel-baas.firebaseio.com/articles.json",
          headers: {
            "Content-type": "application/json",
          },
          json: this.state.newarticle,
        };
        request.post(options, function(error, response, body) {
          notify.show("追加完了", "success");
          window.location.replace("/edit");
        });
      } catch (e) {
        console.log(e);
      }
    }

    // 更新
    try {
      var options = {
        uri: "https://accel-baas.firebaseio.com/articles.json",
        headers: {
          "Content-type": "application/json",
        },
        json: this.state.articles,
      };
      request.patch(options, function(error, response, body) {
        notify.show("更新完了", "success");
        //window.location.replace("/edit");
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    return (
      <div id="kv" className="block-kv2 clearfix">
        <a name="About"></a>
        <Notifications />
        <Container className="container__kv" id="container__kv">
          <Row className="show-grid content-grid">
            <Col className="content__kv" xs="12" md="12">
              {this.renderList()}

              <div>
                <p>新規記事</p>
                <h1>
                  <input
                    type="text"
                    name="uri"
                    value={this.state.newarticle.uri}
                    onChange={this.new_uri}
                    placeholder="URI"
                  />
                  <input
                    type="text"
                    name="tag"
                    value={this.state.newarticle.tag}
                    onChange={this.new_tag}
                    placeholder="tag"
                  />
                  <input
                    type="text"
                    name=""
                    value={this.state.newarticle.created_at}
                    onChange={this.new_created_at}
                    placeholder="公開日時"
                  />
                  <br />
                  <input
                    type="text"
                    name="thumbnail"
                    value={this.state.newarticle.thumbnail}
                    onChange={this.new_thumbnail}
                    placeholder="サムネURL"
                  />
                  <input
                    type="text"
                    name="meta_title"
                    value={this.state.newarticle.meta_title}
                    onChange={this.new_meta_title}
                    placeholder="meta title"
                  />
                  <input
                    type="text"
                    name="keywords"
                    value={this.state.newarticle.keywords}
                    onChange={this.new_keywords}
                    placeholder="meta keywords"
                  />
                  <input
                    type="text"
                    name="description"
                    value={this.state.newarticle.description}
                    onChange={this.new_description}
                    placeholder="meta description"
                  />
                </h1>
                <h2>
                  <input
                    type="text"
                    name="title"
                    value={this.state.newarticle.title}
                    onChange={this.new_title}
                    placeholder="title"
                  />
                </h2>
                <p>
                  <textarea
                    name="body"
                    value={this.state.newarticle.body}
                    onChange={this.new_body}
                    placeholder="body"
                  />
                </p>
              </div>
              <input
                type="button"
                value="save"
                onClick={(e) => {
                  this.save(e);
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
