import React from 'react'
import Layout from '../layouts/';
import ContactComplete from '../components/ContactComplete';
const ContactCompletePage = () => (
  <Layout lang="日本語">
		<ContactComplete lang="日本語"/>
  </Layout>
)

export default ContactCompletePage

