import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Index from "./pages/index.js";
import Partner from "./pages/partner.js";
import Startups from "./pages/start-ups.js";
import Contents from "./pages/contents.js";
import Edit from "./pages/Edit.js";
import Contact from "./pages/contact.js";
import ContactDetails from "./pages/content-details.js";
import ContactConfirm from "./pages/contact-confirm.js";
import ContactComplete from "./pages/contact-complete.js";
import ApplyComplete from "./pages/apply-complete.js";

import enIndex from "./pages/en/index.js";
import enPartner from "./pages/en/partner.js";
import enStartups from "./pages/en/start-ups.js";
import enContents from "./pages/en/contents.js";
import enContact from "./pages/en/contact.js";
import enContactDetails from "./pages/en/content-details.js";
import enContactConfirm from "./pages/en/contact-confirm.js";
import enContactComplete from "./pages/en/contact-complete.js";
import enApplyComplete from "./pages/en/apply-complete.js";
import forbizTop from "./pages/forbiz";
import ProgramDetail from "./pages/forbiz/Program";
import Topics from "./pages/forbiz/Topics";
import TopicDetail from "./pages/forbiz/TopicDetail.js";
import Request from "./pages/forbiz/Request.js";
import RequestThanks from "./pages/forbiz/Thanks.js";
import forbizLP from "./pages/forbiz/Lp.js";
import forbizLPThanks from "./pages/forbiz/LPThanks.js";

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" component={Index} />
          <Route exact path="/partner" component={Partner} />
          <Route exact path="/start-ups" component={Startups} />
          <Route exact path="/contents" component={Contents} />
          <Route exact path="/content-details/:id" component={ContactDetails} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/contact-confirm" component={ContactConfirm} />
          <Route exact path="/contact-complete" component={ContactComplete} />
          <Route exact path="/apply-complete" component={ApplyComplete} />

          <Route exact path="/en" component={enIndex} />
          <Route exact path="/en/partner" component={enPartner} />
          <Route exact path="/en/start-ups" component={enStartups} />
          <Route exact path="/en/contents" component={enContents} />
          <Route
            exact
            path="/en/content-details"
            component={enContactDetails}
          />
          <Route exact path="/edit" component={Edit} />
          <Route exact path="/en/contact" component={enContact} />
          <Route
            exact
            path="/en/contact-confirm"
            component={enContactConfirm}
          />
          <Route
            exact
            path="/en/contact-complete"
            component={enContactComplete}
          />
          <Route exact path="/en/apply-complete" component={enApplyComplete} />

          <Route exact path="/forbiz/" component={forbizTop} />
          <Route exact path="/forbiz/program" component={ProgramDetail} />
          <Route exact path="/forbiz/topics" component={Topics} />
          <Route exact path="/forbiz/topics/:id" component={TopicDetail} />
          <Route exact path="/forbiz/request" component={Request} />
          <Route exact path="/forbiz/thanks" component={RequestThanks} />
          <Route exact path="/forbiz/lp" component={forbizLP} />
          <Route exact path="/forbiz/lp/thanks" component={forbizLPThanks} />
        </div>
      </Router>
    );
  }
}

export default App;
