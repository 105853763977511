import React from "react";

import request from "request";

import Notifications, { notify } from "react-notify-toast";

var MY_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
var slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);
slack.onError = function(err) {
  console.log("API error:", err);
};

var state = {
  check1: false,
  check2: false,
  check3: false,
  check4: false,
  check5: false,
  check6: false,
  etc: "",
  company_name: "",
  name: "",
  department: "",
  title: "",
  tel: "",
  email: "",
  url: "",
  source: "",
};

function onConfirm(e) {
  console.log(state);

  // 整形
  var needs = "";
  var needs_text = {
    1: "プログラム全般/研修の詳細について",
    2: "事業立ち上げ（仮説検証やプロダクト開発）支援について",
    3: "価格について",
    4: "他社サービスとの比較",
    5: "導入事例",
    6: "その他",
  };
  for (let i = 1; i <= 6; i++) {
    if (state["check" + i] === true) {
      if (needs !== "") {
        needs += ", ";
      }
      needs += needs_text[i];
    }
  }
  if (state.etc !== "") {
    needs += "（" + state.etc + "）";
  }

  var notif_message =
    "[AP_BIZ]企業からお問い合わせがありました！" +
    "\n求めている情報:" +
    needs +
    "\n会社名:" +
    state.company_name +
    "\n担当者名:" +
    state.name +
    "\n部署名:" +
    state.department +
    "\n役職:" +
    state.title +
    "\n電話番号:" +
    state.tel +
    "\nメールアドレス:" +
    state.email +
    "\nコーポレートサイト:" +
    state.url +
    "\n流入元:" +
    state.source;

  console.log(notif_message);

  slack.success(notif_message);

  window.location.href = "https://accel-program.io/forbiz/thanks"
}

function change(id, v) {
  state[id] = v;
}

function Contact() {
  return (
    <div id="contect" className="section_contact">
      <Notifications />
      <h3>CATALOG REQUEST</h3>
      <div className="form_block">
        <form>
          <div className="form_group">
            <span className="label">
              どのような情報をお求めですか（複数選択可）<span>*</span>
            </span>
            <div className="form_check">
              <label>
                <input
                  type="checkbox"
                  name=""
                  value="プログラム全般/研修の詳細について"
                  onChange={(e) => {
                    change("check1", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>プログラム全般/研修の詳細について</small>
                </span>
              </label>

              <label>
                <input
                  type="checkbox"
                  name=""
                  value="事業立ち上げ（仮説検証やプロダクト開発）支援について"
                  onChange={(e) => {
                    change("check2", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>
                    事業立ち上げ（仮説検証やプロダクト開発）支援について
                  </small>
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name=""
                  value="価格について"
                  onChange={(e) => {
                    change("check3", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>価格について</small>
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name=""
                  value="他社サービスとの比較"
                  onChange={(e) => {
                    change("check4", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>他社サービスとの比較</small>
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name=""
                  value="導入事例"
                  onChange={(e) => {
                    change("check5", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>導入事例</small>
                </span>
              </label>
              <label>
                <input
                  type="checkbox"
                  name=""
                  value="その他（自由入力）"
                  onChange={(e) => {
                    change("check6", e.target.checked);
                  }}
                />
                <span className="label-text">
                  <small>その他（自由入力）</small>
                </span>
              </label>
            </div>
            <textarea
              onChange={(e) => {
                change("etc", e.target.value);
              }}
            />
          </div>
          <div className="form_row">
            <div className="form_group">
              <label>会社名</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("company_name", e.target.value);
                }}
              />
            </div>

            <div className="form_group">
              <label>ご担当者様名</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("name", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_group">
              <label>部署名</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("department", e.target.value);
                }}
              />
            </div>
            <div className="form_group">
              <label>役職</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("title", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_group">
              <label>ご担当者様電話番号</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("tel", e.target.value);
                }}
              />
            </div>
            <div className="form_group">
              <label>
                ご担当者様メールアドレス <span>*</span>
              </label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("email", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_group">
              <label>コーポレートサイトURL</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("url", e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <label>このサイトを知ったきっかけ</label>
              <div className="select-wrap">
                <select
                  name=""
                  id=""
                  onChange={(e) => {
                    change("source", e.target.value);
                  }}
                >
                  <option value="">SELECT</option>
                  <option value="検索を見て">検索を見て</option>
                  <option value="知人からのご紹介で">知人からのご紹介で</option>
                  <option value="SNSで見て">SNSで見て</option>
                  <option value="当社主催のイベントで">
                    当社主催のイベントで
                  </option>
                  <option value="当社からのDMで">当社からのDMで</option>
                  <option value="当社のサイト・メディアを見て">
                    当社のサイト・メディアを見て
                  </option>
                  <option value="その他">その他</option>
                </select>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="submit"
            onClick={(e) => {
              onConfirm(e);
            }}
          >
            <img src="/assets/images/forbiz/more.svg" />
            <span>送る</span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
