import React from 'react'
import Layout from '../../layouts/';
import Startups from '../../components/Startups';
import Contact from '../../components/Contact';
const StartupPage = () => (
  <Layout lang="en">
    <Startups lang="en"/>
		<Contact lang="en"/>
  </Layout>
)

export default StartupPage
