import React, { Fragment, useState, useEffect } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, } from 'reactstrap';
import data from '../data/feature.json';

const Feature = (props) => {
	const [text, setText] = useState(data[props.lang]);

  return (
      <section>
				<Container>
					<h2>FEATURE</h2>
					<div className="content-wrap">
						<Row>
							<Col sm="6" xs="12" className="pr-0">
								<h3>{text.performance.title}</h3>
								<p>
								{text.performance.text}
								</p>
							</Col>
							<Col sm="6" xs="12">
								<Row className="sp-ml-m15">
									<Col xs="4" className="pr-0" />
									<Col sm="4" xs="4" className="pr-0">
										<img className="thumb-100" src="/assets/images/feature/feature_1.png" /> 
									</Col>
									<Col sm="4" xs="4" className="pr-0">
										<img className="thumb-100" src="/assets/images/feature/feature_3.png" /> 
									</Col>
								</Row>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col sm="6" xs="12" className="pr-0">
								<h3>{text.relationship.title}</h3>
								<p>{text.relationship.text}</p>
							</Col>
							<Col sm="6" xs="12">
								<Row className="sp-ml-m15">
									<Col xs="4" className="pr-0" />
									<Col xs="4" className="pr-0">
										<img className="thumb-100" src="/assets/images/feature/feature_4.png" /> 
									</Col>
									<Col xs="4" className="pr-0">
										<img className="thumb-100" src="/assets/images/feature/feature_5.png" /> 
									</Col>
								</Row>
							</Col>
						</Row>
						<hr />
						<Row>
							<Col sm="6" xs="12" className="pr-0">
								<h3>{text.development.title}</h3>
								<p>
								{text.development.text}
								</p>
							</Col>
							<Col sm="6" xs="12">
								<Row>
								<Col sm={{ size:8, offset:4}} xs="12" className="pr-0">
									<img className="thumb-100" src="/assets/images/feature/feature_6.png" /> 
								</Col>
								</Row>
							</Col>
						</Row>

					</div>
				</Container>
			</section>
  );
};

export default Feature;