import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card, CardText, CardBody,
	Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
const ContactForm = () => {
  return (
		<Fragment>
		<section className="container-header container-startups">
			<Container>
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/">TOP</a></li>
							<li className="breadcrumb-item" aria-current="page">CONTACT</li>
						</ol>
					<h2>CONTACT</h2>
			</Container>
		</section>
      <section className="container-contact">
				<Container>
					<div className="content-wrap">
						<h2>Let's join, too!</h2>
						<Row>
							<Col sm="12" xs="12">
								<Card>
										<CardBody>
											<h3>APPLY  <span>2019 BATCH#4</span></h3>
											<hr />
											<Form className="form">
											<Row form>
												<Col md={6}>
													<FormGroup>
														<Label for="projectName">Project name<span>*</span></Label>
														<Input type="text" name="projectName" id="projectName" placeholder="Please enter a project name" required/>
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<Label for="repreName">Representative's name<span>*</span></Label>
														<Input type="password" name="RepreName" id="repreName" placeholder="Please enter a representative's name" />
													</FormGroup>
												</Col>
											</Row>
											<Row form>
												<Col md={6}>
													<FormGroup>
														<Label for="email">E-mail<span>*</span></Label>
														<Input type="email" name="email" id="email" placeholder="Please enter your e-mail address" />
													</FormGroup>
												</Col>
												<Col md={6}>
													<FormGroup>
														<Label for="status">Type<span>*</span></Label>
														<Input type="select" name="status" id="status" >
															<option> Please tell us about your current status</option>
															<option>2</option>
															<option>3</option>
															<option>4</option>
															<option>5</option>
														</Input>
													</FormGroup>
												</Col>
											</Row>
												<FormGroup>
													<Label for="more">And more</Label>
													<Input type="textarea" name="more" id="more" placeholder="Please enter a specific plan"/>
												</FormGroup>

												<Link className="back" to="/contact-comfirm/"><Button className="btn-primary btn-submit">OK</Button></Link>
											</Form>
										</CardBody>
									</Card>
							</Col>
						</Row>

					</div>
				</Container>
			</section>
			</Fragment>
  );
};

export default ContactForm;