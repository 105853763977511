import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card, CardImg, CardText, CardBody} from 'reactstrap';
import data from '../data/startup.json';
const Startup = (props) => {
	const [text, setText] = useState(data[props.lang]);
  return (
      <section id="startup" className="container-startup">
				<Container>
					<h2>Our Portfolio</h2>
					<div className="content-wrap">
						<Row>
							<Col sm="12" xs="12">
								<p>{text.text}</p>
							</Col>
						</Row>
						<hr />
						<Row>
		                    {text.startups.filter((v,i) => i < 6).map((list) => {
		                        return (
									<Col sm="4" xs="12">
										<Card>
											<div className="thumb-wrap">
												<CardImg top width="100%" src={list.img} alt="Card image cap" />
											</div>
											<CardBody>
												<h3>{list.title}</h3>
												<CardText>{list.text}</CardText>
												<Link to={props.lang === '日本語' ? "/start-ups/" : "/en/start-ups/" }>Read more</Link>
											</CardBody>
										</Card>
									</Col>
		                        )
		                    })}
						</Row>
						<Link to={props.lang === '日本語' ? "/start-ups/" : "/en/start-ups/" } className="btn btn-dark btn-more">Read all portfolio</Link>
					</div>
				</Container>
			</section>
  );
};

export default Startup;