import React from 'react'
import Layout from '../layouts/';
import ContactForm from '../components/ContactForm';
const ContactPage = () => (
  <Layout lang="日本語">
		<ContactForm lang="日本語"/>
  </Layout>
)

export default ContactPage

