import React from "react";
import { saveAs } from "file-saver";
import ReactPixel from 'react-facebook-pixel';

function Contact() {
  if (window.location.pathname.length >= 14) {
    if ("/forbiz/thanks" === window.location.pathname.substr(0, 14)) {
      const advancedMatching = { em: 'info@techfund.jp' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('7425321387485832', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
      ReactPixel.track('Contact');

      // GA
     
      let script3 = document.createElement('script');
      // プレーンテキスト、グローバルな window.gtag 関数を定義するため
      script3.text = `gtag('event', 'conversion', {'send_to': 'AW-10837794128/5gRHCIytkJADENC67q8o'});`;
      document.body.appendChild(script3);
    }
  }
  return (
    <div id="contect" className="section_contact">
      <h3>CATALOG REQUEST</h3>

      <div class="subtitle">
        <p>送信が完了しました。<br />数日以内に担当者よりご連絡させていただきます。</p>
      </div>
    </div>
  );
}

export default Contact;
