import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, } from 'reactstrap';
import data from '../data/support.json';
import {checkText} from '../utils/common.js';

const Support = (props) => {
	const [text, setText] = useState(data[props.lang]);

  return (
      <section className="container-dark container-support">
				<Container>
					<h2>How we support for Token Offering?</h2>
					<div className="timeline">
						<Row>
							<Col xs="1">
								<div className="mt-6" />
								<span className="number">01</span>
							</Col>
							<Col xs="1">
								<div className="mt-6" />
								<span className="timeline-tick" />
							</Col>
							<Col xs="10">
								<Row>
									<Col sm={{size:5, offset:0}} xs={{size:11, offset:1}}>
										<img className="thumb-100" src="/assets/images/support/business_scheme.jpg" /> 
									</Col>
									<Col sm={{size:7, offset:0}} xs={{size:11, offset:1}}>
										<h3>{text.scheme.title}</h3>
										{/* <h4>Layout of the Business Scheme and Token Economy</h4> */}
										<p>{checkText(text.scheme.text)}</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs="1">
								<div className="mt-6" />
								<span className="number">02</span>
							</Col>
							<Col xs="1">
								<div className="mt-6" />
								<span className="timeline-tick" />
							</Col>
							<Col xs="10">
								<Row>
									<Col sm={{size:5, offset:0}}  xs={{size:11, offset:1}}>
										<img className="thumb-100" src="/assets/images/support/development.jpg" /> 
									</Col>
									<Col sm={{size:7, offset:0}} xs={{size:11, offset:1}}>
										<h3>{text.development.title}</h3>
										{/* <h4>Layout of the Business Scheme and Token Economy</h4> */}
										<p>{checkText(text.development.text)}</p>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col xs="1">
								<div className="mt-6" />
								<span className="number">03</span>
							</Col>
							<Col xs="1">
								<div className="mt-6" />
								<span className="timeline-tick" />
							</Col>
							<Col xs="10">
								<Row>
									<Col sm={{size:5, offset:0}} xs={{size:11, offset:1}}>
										<img className="thumb-100" src="/assets/images/support/marketing.jpg" /> 
									</Col>
									<Col sm={{size:7, offset:0}} xs={{size:11, offset:1}}>
										<h3>{text.marketing.title}</h3>
										{/* <h4>Layout of the Business Scheme and Token Economy</h4> */}
										<p>{checkText(text.marketing.text)}</p>
									</Col>
								</Row>
							</Col>
						</Row>
					</div>
					<div className="section-2">
						<Row className="mt-5">
							<Col sm="6" xs="12">
								<img className="thumb-100" src="/assets/images/support/demoday.jpg" />
							</Col>
							<Col sm="6" xs="12">
								<h3>{text.demoday.title}</h3>
								{/* <h4>text text text text text text text text text text text</h4> */}
								<p>{checkText(text.demoday.text)}</p>
							</Col>
						</Row>
					</div>

				</Container>

			</section>
  );
};

export default Support;