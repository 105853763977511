import React from "react";
import topics from "../../data/forbiz/topics.json";
import { Link } from "react-router-dom";
function Topics() {
  return (
    <div id="topics" className="section_topics">
      <div className="wrap_contents">

        <div className="grid">
          <Link to={`/forbiz/topics/${topics.topics[0].id}`}>
            <div className="content_block lg">
              <img
                className="thumb"
                src={topics.topics[0].img}
              />
              <img
                className="thumb_active"
                src={topics.topics[0].active_img}
              />
              <div className="text_block">
                <h3>{topics.topics[0].title}</h3>
                <div className="tag">
                {
                  topics.topics[0].tags.map((tag) => (
                    <span>{tag}</span>
                  ))
                }
                </div>
                <p className="desc">
                  {topics.topics[0].desc}
                </p>
                <div className="more">
                  <img src="/assets/images/forbiz/more.svg" />
                  <span>もっと読む</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="grid">
          {topics.topics.slice(1, 3).map((t => (
            <Link to={`/forbiz/topics/${t.id}`}>
              <div className="content_block sm">
                <img
                  className="thumb"
                  src={t.img}
                />
                <img
                  className="thumb_active"
                  src={t.active_img}
                />
                <div className="text_block">
                  <h3>{t.title}</h3>
                  <div className="tag">
                  {
                    t.tags.map((tag) => (
                      <span>{tag}</span>
                    ))
                  }
                  </div>

                  <p className="desc">
                    {t.desc}
                  </p>
                  <div className="more">
                    <img src="/assets/images/forbiz/more.svg" />
                    <span>もっと読む</span>
                  </div>
                </div>
              </div>
            </Link>
          )))
        }
        </div>
      </div>
      <div className="content_footer">
        <a href="/forbiz/topics" className="more_all">
          <img src="/assets/images/forbiz/more.svg" />
          <span>全ての記事を読む</span>
        </a>
      </div>
    </div>
  );
}

export default Topics;
