import React from "react";
import { saveAs } from "file-saver";
import ReactPixel from 'react-facebook-pixel';

function download1(e) {
  saveAs(
    "https://accel-program.io/assets/pdfs/forbiz/lp/whitepaper1.pdf",
    "株式会社TECHFUND_ホワイトペーパー_新規事業開発支援_事例集.pdf"
  );
}

function download2(e) {
  saveAs(
    "https://accel-program.io/assets/pdfs/forbiz/lp/whitepaper2.pdf",
    "株式会社TECHFUND_ホワイトペーパー_システム開発支援_事例集.pdf"
  );
}

function Contact() {
  if (window.location.pathname.length >= 17) {
    if ("/forbiz/lp/thanks" === window.location.pathname.substr(0, 17)) {
      const advancedMatching = { em: 'info@techfund.jp' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      ReactPixel.init('7425321387485832', advancedMatching, options);
      ReactPixel.pageView(); // For tracking page view
      ReactPixel.track('Contact');

      // GA
     
      let script3 = document.createElement('script');
      // プレーンテキスト、グローバルな window.gtag 関数を定義するため
      script3.text = `gtag('event', 'conversion', {'send_to': 'AW-10837794128/5gRHCIytkJADENC67q8o'});`;
      document.body.appendChild(script3);
    }
  }
  return (
    <div id="contect" className="section_contact">
      <h3>WHITE PAPER DOWNLOAD</h3>

      <div class="subtitle">
        <p>送信が完了しました。<br />後日担当者よりご連絡させていただきます。下記より資料をダウンロードください。</p>

        <div className="whitepaper-download">
          <img src="/assets/images/forbiz/whitepaper.png" className="whitepaper-img" />

          <div className="form_row">
            <button
              type="button"
              className="submit button-left"
              onClick={(e) => {
                download1(e);
              }}
            >
              <img src="/assets/images/forbiz/more.svg" />
              <span>新規事業支援 ホワイトペーパーダウンロード</span>
            </button>

          </div>
          <div className="form_row">

            <button
              type="button"
              className="submit button-left"
              onClick={(e) => {
                download2(e);
              }}
            >
              <img src="/assets/images/forbiz/more.svg" />
              <span>システム開発支援 ホワイトペーパーダウンロード</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
