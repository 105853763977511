import React from "react";

function WhatWeDo() {
  return (
    <div id="what-we-do" className="section_wwd">
      <img
        className="particle pc_only"
        src="/assets/images/forbiz/particle.svg"
      />
      <img
        className="particle sp_only"
        src="/assets/images/forbiz/particle_sp.svg"
      />
      <div className="wrap_contents">
        <div className="block_contents">
          <h2 className="title_exp">EXPERIENCE</h2>
          <div className="block_exp">
            <img src="/assets/images/forbiz/logo/nomura.png" />
            <img src="/assets/images/forbiz/logo/softbank.png" />
            <img src="/assets/images/forbiz/logo/uzabase.png" />
            <img src="/assets/images/forbiz/logo/jcb.png" />
            <img src="/assets/images/forbiz/logo/tokyodome.png" />
            <img src="/assets/images/forbiz/logo/nec.png" />
            <img src="/assets/images/forbiz/logo/mitsubishiestate.png" />
            <img src="/assets/images/forbiz/logo/daimarumatsuzakaya.png" />
            <img src="/assets/images/forbiz/logo/etc.png" />
          </div>
        </div>
        <div className="block_contents">
          <h1>
            A PROGRAM
            <br />
            FOR SUCCESS OF
            <br />
            NEW BUSINESS
          </h1>
          <p className="subtitle">
            新規事業の確実な成功に向けた、手応えを掴むプログラム
          </p>
          <p className="desc">
            多くの実績を元に様々な角度から最適な組織設計・事業創出を支援します。
            <br />
            社内にある多くのアイデアや優秀な人材を発掘し育成するインキュベーションプログラム
            <br className="pc-only"/>
            から、ユーザーに求められるサービスを共に模索し企画提案力と開発力でPOCの可能性を
            <br className="pc-only"/>
            最大化する事業立ち上げ支援までをトータルサポートします。
          </p>
        </div>
      </div>
    </div>
  );
}

export default WhatWeDo;
