import React from "react";

import request from "request";

import Notifications, { notify } from "react-notify-toast";

var MY_SLACK_WEBHOOK_URL =
  "https://hooks.slack.com/services/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";
var slack = require("slack-notify")(MY_SLACK_WEBHOOK_URL);
slack.onError = function(err) {
  console.log("API error:", err);
};

var state = {
  check1: false,
  check2: false,
  check3: false,
  check4: false,
  check5: false,
  check6: false,
  etc: "",
  company_name: "",
  name: "",
  department: "",
  title: "",
  tel: "",
  email: "",
  url: "",
  source: "",
};

function onConfirm(e) {
  console.log(state);

  var notif_message =
    "[AP_BIZ_LP]企業からお問い合わせがありました！" +
    "\n会社名:" +
    state.company_name +
    "\n担当者名:" +
    state.name +
    "\n電話番号:" +
    state.tel +
    "\nメールアドレス:" +
    state.email;

  console.log(notif_message);

  slack.success(notif_message);

  window.location.href = "https://accel-program.io/forbiz/lp/thanks"
}

function change(id, v) {
  state[id] = v;
}

function Contact() {
  return (
    <div id="contect" className="section_contact">
      <Notifications />
      <h3>WHITE PAPER DOWNLOAD</h3>

      <div class="subtitle">
        <p>以下の項目を記入し、「送信する」ボタンを押してください。<br />資料ダウンロードのURLが記載されていますので、URLにアクセスし資料をご確認ください。</p>
      </div>

      <div className="lp_text_block">
        <p>こんな事にお困りの方に向けたホワイトペーパーをプレゼント</p>
        <p>・新規事業の立ち上げ方がわからない。<br />・既にアイデアはあるが検証方法がわからない。<br />・アイデアをカタチにするリソースがない。 </p>
        <div className="section_portfolio">
          <img src="/assets/images/forbiz/logo/nomura.png" />
          <img src="/assets/images/forbiz/logo/softbank.png" />
          <img src="/assets/images/forbiz/logo/uzabase.png" />
          <img src="/assets/images/forbiz/logo/jcb.png" />
          <img src="/assets/images/forbiz/logo/tokyodome.png" />
          <img src="/assets/images/forbiz/logo/nec.png" />
          <img src="/assets/images/forbiz/logo/mitsubishiestate.png" />
          <img src="/assets/images/forbiz/logo/daimarumatsuzakaya.png" />
          <img src="/assets/images/forbiz/logo/etc.png" />
        </div>
      </div>

      <div className="lp_form_block">
        <form>
          <div className="form_row">
            <div className="form_group">
              <label>会社名</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("company_name", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">

            <div className="form_group">
              <label>ご担当者様名</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("name", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_group">
              <label>ご担当者様電話番号</label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("tel", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="form_row">
            <div className="form_group">
              <label>
                ご担当者様メールアドレス <span>*</span>
              </label>
              <input
                className="text"
                type="text"
                onChange={(e) => {
                  change("email", e.target.value);
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="submit"
            onClick={(e) => {
              onConfirm(e);
            }}
          >
            <img src="/assets/images/forbiz/more.svg" />
            <span>送信する</span>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
