import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
  Container,
  Col,
  Row} from 'reactstrap';
const Footer = () => {
  const [state, setState] = useState({ isOpen: false});
  const [modalState, setModalState] = useState({ isModal: false});
  const [dropdownState, setDropdownState] = useState({ isDropdown: false});
  const handleModalChange = () => setModalState({isModal: !modalState.isModal});
  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdownState({isDropdown: !dropdownState.isDropdown});
  }
  return (
      <Fragment>
        <div className="footer">
          <a href="#" className="btn-top"/>
          <Container>
            <Row className="no-gutters">
              <Col sm={{size:8, order:1}} xs={{size:12, order:2,  offset:0}} >
              <ul className="list-inline">
											<li className="list-inline-item">
												<Link to="/">PROGRAM</Link>
											</li>
											<li className="list-inline-item">
                        <Link to="/#startup">STARTUPS</Link>
											</li>
                      <li className="list-inline-item">
                        <a href="http://techfund.jp/#contact">CONTACT</a>
											</li>
                      <li className="list-inline-item">
                        <Link className="btn-primary" to="/#apply" >APPLY</Link>
                      </li>
                      <li className="list-inline-item pc-only">
                        <a className="btn-primary" href="https://techfund.jp/#contact">お問い合わせ</a>
                      </li>
									</ul>
              </Col>
              <Col sm={{size:4, order:1}} xs={{size:12, order:1 , offset:0}}>
                <ul className="list-inline list-sns">
                  <li className="list-inline-item">
                    <a href="https://www.facebook.com/techfund.inc/" target="_blank"><img className="icon-sns" src="/assets/images/sns/facebook.png" /></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://twitter.com/techfund_inc" target="_blank"><img className="icon-sns" src="/assets/images/sns/twitter.png" /></a>
                  </li>
                  <li className="list-inline-item">
                    <a href="https://www.linkedin.com/company/7947465/admin/" target="_blank"><img className="icon-sns" src="/assets/images/sns/linkedin.png" /></a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
          <div className="block-cr">
              <Container>
                <span>Copyright (C) 2019 <a href="https://techfund.jp" target="_blank">TECHFUND PTE,LTD</a>. All Rights Reserved.</span>
              </Container>
          </div>
        </div>
      </Fragment>
  );
};

export default Footer;
