import React from 'react'
import Layout from '../../layouts/';
import Contents from '../../components/Contents';
import Contact from '../../components/Contact';
const ContentsPage = () => (
  <Layout lang="en">
    <Contents lang="en"/>
		<Contact lang="en"/>
  </Layout>
)

export default ContentsPage

