import React from 'react'
import Layout from '../layouts/';
import Kv from '../components/Kv';
import Feature from '../components/Feature';
import Support from '../components/Support';
import Startup from '../components/Startup';
import Team from '../components/Team';
import Faq from '../components/Faq';
import News from '../components/News';
import Contact from '../components/Contact';

export default class IndexPage extends React.Component {
    render() {
        return (
  <Layout lang="日本語">
    <Kv />
    <Feature lang="日本語"/>
    <Support lang="日本語"/>
    <Startup lang="日本語"/>
    <Team lang="日本語"/>
    <Faq lang="日本語"/>
    {/*<News lang="日本語"/>*/}
    <Contact lang="日本語" />
  </Layout>
        );
    }
}
