import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Modal,
  ModalBody,
  Button,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Col,
  Row,
} from "reactstrap";
const HeaderSp = (props) => {
  const [language, setLanguage] = useState({ isOpen: false });
  const [state, setState] = useState({ isOpen: false });
  const [modalState, setModalState] = useState({ isModal: false });
  const [dropdownState, setDropdownState] = useState({ isDropdown: false });
  const handleModalChange = () =>
    setModalState({ isModal: !modalState.isModal });
  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdownState({ isDropdown: !dropdownState.isDropdown });
  };
  useEffect(() => {}, []);

  return (
    <Fragment>
      <Navbar className="header sp_only" color="dark" expand="md">
        <Modal isOpen={modalState.isModal} toggle={handleModalChange}>
          {/* <ModalHeader toggle={handleModalChange}>Modal title</ModalHeader> */}
          <ModalBody>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalState({ isModal: !modalState.isModal })}
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <Nav className="block-menu">
              <NavItem>
                <NavLink href="/forbiz/program">PROGRAM</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/forbiz/topics">TOPICS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/forbiz/request">資料請求</NavLink>
              </NavItem>
            </Nav>
          </ModalBody>
        </Modal>
        <NavbarBrand href="/forbiz/">
          <Row className="no-gutters">
            <Col xs="6">
              <img
                className="header_logo"
                src="/assets/images/forbiz/logo_sp.svg"
              />
            </Col>
          </Row>
        </NavbarBrand>
        {/* <NavbarToggler onClick={() => setState({isOpen: !state.isOpen})} /> */}
        <button
          type="button"
          className={`navbar-toggler`}
          onClick={() => setModalState({ isModal: !modalState.isModal })}
        >
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
      </Navbar>
    </Fragment>
  );
};

export default HeaderSp;
