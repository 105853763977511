import React from "react";

function Footer() {
  return (
    <div className="footer">
      <div className="footer_content">
        <span className="active">2021©TECHFUND INC.</span>
        <span>CONTACT_INFO@TECHFUND.JP</span>
      </div>
    </div>
  );
}

export default Footer;
