import React from "react";
import { Link } from "react-router-dom";
function Strengthes({ more }) {
  return (
    <div id="strengthes" className="section_stg">
      <img
        className="bg_image pc_only"
        src="/assets/images/forbiz/strengthes.svg"
      />
      <img
        className="bg_image sp_only"
        src="/assets/images/forbiz/strengthes_sp.svg"
      />
      <div className="wrap_contents">
        <div className="content_block">
          <label>STRENGTH 01</label>
          <h1>DESIGN OF PROGRAM</h1>
          <p className="subtitle">プログラム設計力</p>
          <p className="desc">
            野村ホールディングス、JCBなど、国内大手企業約30社との実績があり、様々なアクセラレーションプログラムを設計してきました。
            <br />
            その基盤となっているのは、TECHFUND自身が約5年間、300社を超えるスタートアップに対してアクセラレーションプログラムを提供してきた実績です。
            <br />
            プログラム運営のためのツールも独自開発をしています。
          </p>
        </div>
        <div className="content_block">
          <label>STRENGTH 02</label>
          <h1>
            FROM IDEA
            <br className="pc_only" />
            TO RELEASE
          </h1>
          <p className="subtitle">アイデアからリリースまで</p>
          <p className="desc">
            ビジネスモデルの設計から開発、マーケティングまでを一貫して提供可能です。リーンスタートアップのサイクルに合わせた柔軟な開発を行うことにより高速な仮説検証を実現します。また、セキュリティ監査をはじめとした外部監査を数多くクリアしてきた実績があるため品質の高いプロダクトをリリースできます。
          </p>
        </div>
        <div className="content_block">
          <label>STRENGTH 03</label>
          <h1>EXPERIENCED CONSULTANTS</h1>
          <p className="subtitle">経験豊富なコンサルタント</p>
          <p className="desc">
            元VCやメガベンチャーでアクセラレーションプログラムの設計や運用を行ってきたメンバーが貴社の新規事業支援を行います。様々なビジネスの知識を使ってプロダクトのPMFから収益化まで、事業として成り立つサービスのリリースをお手伝いいたします。
          </p>
          {more && (
            <Link to="/forbiz/program" className="more">
              <img src="/assets/images/forbiz/more.svg" />
              <span>サービスについてもっと読む</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Strengthes;
