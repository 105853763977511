import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  Modal,
  ModalBody,
  Button,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Col,
  Row,
} from "reactstrap";
const Header = (props) => {
  const [language, setLanguage] = useState({ isOpen: false });
  const [state, setState] = useState({ isOpen: false });
  const [modalState, setModalState] = useState({ isModal: false });
  const [dropdownState, setDropdownState] = useState({ isDropdown: false });
  const handleModalChange = () =>
    setModalState({ isModal: !modalState.isModal });
  const handleDropdown = (e) => {
    e.preventDefault();
    setDropdownState({ isDropdown: !dropdownState.isDropdown });
  };

  const onChangeLang = (val) => {
    setLanguage({ language: val });
  };

  useEffect(() => {
    const locale = Intl.NumberFormat().resolvedOptions().locale;
    if (!localStorage.getItem("access")) {
      if (!locale.match(/ja/)) {
        //props.history.push('/en'+props.location.pathname)
      }
      localStorage.setItem("access", true);
    }

    if (props.lang === "日本語") {
      setLanguage({ language: "日本語" });
    }
    if (props.lang === "en") {
      setLanguage({ language: "ENGLISH" });
    }
  }, []);

  return (
    <Fragment>
      <Navbar className="header" color="light" light expand="md">
        <Modal isOpen={modalState.isModal} toggle={handleModalChange}>
          {/* <ModalHeader toggle={handleModalChange}>Modal title</ModalHeader> */}
          <ModalBody>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalState({ isModal: !modalState.isModal })}
            >
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
              <span className="navbar-toggler-icon"></span>
            </button>

            <Nav className="block-menu">
              <NavItem>
                <NavLink href="/">PROGRAM</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#startup">STARTUPS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="https://techfund.jp/#contact">CONTACT</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#apply">
                  <Button color="primary" onClick={handleModalChange}>
                    APPLY NOW!
                  </Button>
                </NavLink>
              </NavItem>
              <NavItem className="item-lang">
                <NavLink href="">
                  <img className="icon-lang" src="/assets/images/ENGLISH.svg" />
                  <span>English</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/">
                  <img
                    className="icon-lang"
                    src="/assets/images/JAPANESE.svg"
                  />
                  <span>日本語</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <span className="copy-right">© 2019 accel program</span>
              </NavItem>
            </Nav>
          </ModalBody>
        </Modal>
        <NavbarBrand href="/">
          <Row className="no-gutters">
            <Col xs="6">
              <img
                className="header_logo"
                src="/assets/images/logo_header.png"
              />
            </Col>
            <Col xs="6" className="sp-only">
              <a href="/#apply">
                <Button className="sp-btn-apply" color="primary">
                  APPLY
                </Button>
              </a>
              <Dropdown
                isOpen={dropdownState.isDropdown}
                toggle={handleDropdown}
              >
                <DropdownToggle caret>
                  <img className="icon-lang" src="/assets/images/ENGLISH.svg" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem>
                    <img
                      className="icon-lang"
                      src="/assets/images/ENGLISH.svg"
                    />
                    <span className="pl-3">English</span>
                  </DropdownItem>
                  <DropdownItem>
                    <img
                      className="icon-lang"
                      src="/assets/images/JAPANESE.svg"
                    />
                    <span className="pl-3">日本語</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </NavbarBrand>
        {/* <NavbarToggler onClick={() => setState({isOpen: !state.isOpen})} /> */}
        <button
          type="button"
          className={`navbar-toggler`}
          onClick={() => setModalState({ isModal: !modalState.isModal })}
        >
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toggler-icon"></span>
        </button>
        <Collapse navbar>
          <Nav className="ml-auto block-menu--pc" navbar>
            <NavItem>
              <NavLink href="/">PROGRAM</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="/#startup">STARTUPS</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://techfund.jp/#contact">CONTACT</NavLink>
            </NavItem>

            <UncontrolledDropdown nav inNavbar className="tmp-menu">
              <DropdownToggle nav caret>
                <span>STARTUPS</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink href="/#startup">STARTUPS</NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink href="https://techfund.jp/#contact">CONTACT</NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <img
                  className="icon-lang"
                  src={`/assets/images/${language.language}.svg`}
                />
                <span className="pl-1 text-lang">{language.language}</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <Link
                    to={
                      props.lang === "en" ? "" : "/en" + props.location.pathname
                    }
                  >
                    <img
                      className="icon-lang"
                      src="/assets/images/ENGLISH.svg"
                    />
                    <span className="pl-3">English</span>
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link
                    to={
                      props.lang === "日本語"
                        ? ""
                        : props.location.pathname.replace("/en", "")
                    }
                  >
                    <img
                      className="icon-lang"
                      src="/assets/images/JAPANESE.svg"
                    />
                    <span className="pl-3">日本語</span>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <Button className="btn-primary" href="/#apply">
                APPLY
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </Fragment>
  );
};

export default withRouter(Header);
