import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card } from 'reactstrap';
import data from '../data/startup.json';
const Startups = (props) => {
	const [text, setText] = useState(data[props.lang]);
  return (
			<Fragment>
			<section className="container-header container-startups">
				<Container>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">TOP</a></li>
								<li className="breadcrumb-item" aria-current="page">Our Portfolio</li>
							</ol>
						<h2>Our Portfolio</h2>
				</Container>
			</section>
      <section className="container-baige container-su">
				<Container>
					<div className="content-wrap">
						<Row>
							<Col sm="12" xs="12">
								<p>{text.text}</p>
							</Col>
						</Row>
						<hr />
			                {text.startups.map((list) => {
			                    return (
									<Card className="mb-4">
										<Row>
											<Col sm="4" xs="12">
												<div className="thumb-wrap">
													<img className="thumb" src={list.img} />
												</div>
											</Col>
											<Col sm="8" xs="12">
												<h3>{list.title}</h3>
												<p className="mt-2 mb-5">
												{list.text}
												</p>
											</Col>
										</Row>
									</Card>
			                    )
			                })}
					</div>
				</Container>
			</section>
			</Fragment>
  );
};

export default Startups;