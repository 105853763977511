import React, { useEffect, useState } from "react";
import Layout from "../../layouts/forbiz";
import { useParams } from "react-router-dom";
import topics from "../../data/forbiz/topics.json";
function TopicDetail() {
  const { id } = useParams();
  const [topic, setTopic] = useState();

  useEffect(() => {
    //console.log(id)
    const data = topics.topics.find((v) => v.id == id);
    if (data) {
      setTopic(data);
      //console.log(data)
    }
  }, []);
  return (
    <Layout>
      {topic ? (
        <div className="page_topics_detail">
          <img className="thumb" src={topic.img} />
          <h1 className="title">{topic.title}</h1>
          <div className="tag">
            {topic.tags && topic.tags.map((t) => <span>{t}</span>)}
          </div>
          <div className="content_body">
            <p dangerouslySetInnerHTML={{__html: topic.desc}} />
          </div>
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
}

export default TopicDetail;
