import React from 'react'
import Layout from '../layouts/';
import Contents from '../components/Contents';
import Contact from '../components/Contact';
const ContentsPage = () => (
  <Layout lang="日本語">
    <Contents lang="日本語"/>
		<Contact lang="日本語"/>
  </Layout>
)

export default ContentsPage

