import React from "react";

function Flow() {
  return (
    <div id="flow" className="section_flow_wrap">
      <div className="section_flow">
        <div className="flow_block">
          <span>STEP 01</span>
          <h3>INQUIRY</h3>
          <p className="subtitle">お問い合わせ</p>
        </div>
        <div className="flow_block">
          <span>STEP 02</span>
          <h3>MEETING</h3>
          <p className="subtitle">打ち合わせ</p>
        </div>
        <div className="flow_block">
          <span>STEP 03</span>
          <h3>ESTIMATE</h3>
          <p className="subtitle">お見積もり</p>
        </div>
        <div className="flow_block">
          <span>STEP 0４</span>
          <h3>KICKOFF</h3>
          <p className="subtitle">サービス開始</p>
        </div>
      </div>
    </div>
  );
}

export default Flow;
