import React from "react";
import Scrollspy from "react-scrollspy";
function Contentsnav() {
  return (
    <div className="contentnav pc_only">
      <Scrollspy
        items={[
          "kv",
          "what-we-do",
          "strengthes",
          "program",
          "topics",
          "member",
          "flow",
          "about",
          "contect",
        ]}
        currentClassName="active"
      >
        <li className="active">
          <a href="#kv">01</a>
        </li>
        <li>
          <a href="#what-we-do">02</a>
        </li>
        <li>
          <a href="#strengthes">03</a>
        </li>
        <li>
          <a href="#program">04</a>
        </li>
        <li>
          <a href="#topics">05</a>
        </li>
        <li>
          <a href="#member">06</a>
        </li>
        <li>
          <a href="#flow">07</a>
        </li>
        <li>
          <a href="#about">08</a>
        </li>
        <li>
          <a href="#contect">09</a>
        </li>
      </Scrollspy>
    </div>
  );
}

export default Contentsnav;
