import React from "react";

function About() {
  return (
    <div id="about" className="section_about">
      <div className="about_block">
        <h3>ABOUT</h3>
        <table>
          <tbody>
            <tr>
              <th>Company name:</th>
              <td>TECHFUND Inc.</td>
            </tr>
            <tr>
              <th>Capital Stock:</th>
              <td>$1.2 million</td>
            </tr>
            <tr>
              <th>Co-Founder:</th>
              <td>Yuta Matsuyama, Peaske Kawahara</td>
            </tr>
            <tr>
              <th>Address:</th>
              <td>4-22-7, Jingumae, AURA 202, Shibuya, Tokyo, Japan</td>
            </tr>
            <tr>
              <th>Branch:</th>
              <td>1 George St, Level 10, Singapore 049145 (ACN 201900181D)</td>
            </tr>
            <tr>
              <th>E-mail:</th>
              <td>info@techfund.jp</td>
            </tr>
            <tr>
              <th>TEL:</th>
              <td>+81-3-6407-9645</td>
            </tr>
            <tr>
              <th>Business:</th>
              <td>
                Accelerator Leveraging the Growth of Large Companies & <br />
                Startups by “ACCELERATION PROGRAM”.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="about_block">
        <a
          href="https://www.google.co.jp/maps/place/TECHFUND+Inc./@35.668434,139.7064066,17z/data=!3m1!4b1!4m5!3m4!1s0x60188d7c3c109d25:0x12abd2d4f63a35e1!8m2!3d35.668434!4d139.7085953?hl=ja"
          target="_blank"
        >
          <img src="/assets/images/forbiz/map.svg" />
        </a>
      </div>
    </div>
  );
}

export default About;
