import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "reactstrap";
const Kv = () => {
  const [state, setState] = useState({ isOpen: false });
  return (
      <div id="wrapper">
        <div className="movie-wrapper">
          <video className="movie" src="/assets/movies/bg/tf-back-pc.mp4" preload="auto" playsinline="true" autoplay="true" loop="true" muted="true" poster="/assets/images/bg/tf-back-pc.png" />
        </div>  
        <div id="kv" className="block-kv clearfix">
          <Container className="container__kv">
						<h1>ACCEL PROGRAM</h1>
						<p>Accelerate Startups Value with Technology</p>
						<p>Support for development of dApps and token offering</p>
						<a href="#apply"><Button className="btn-primary">APPLY NOW!</Button></a>
          </Container>
        </div>
			</div>
  );
};

export default Kv;
