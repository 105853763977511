import React from 'react'
import Layout from '../layouts/';
import ContentsDetail from '../components/ContentsDetail';
import Contact from '../components/Contact';

export default class ContentsDetailPage extends React.Component {
  componentWillMount() {
    const {params} = this.props.match
    this.setState({article_id: params.id});
  }

  render() {
    return (
  <Layout lang="日本語">
    <ContentsDetail lang="日本語" article_id={this.state.article_id}/>
		<Contact lang="日本語"/>
  </Layout>
    );
  }
}


