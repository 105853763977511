import React, { Fragment, useContext, useState } from "react";
import {Link} from "react-router-dom";
import {
	Container,
	Row,
	Col, Card, CardText, CardBody,
	Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const ContactComplete = () => {
  return (
			<Fragment>
			<section className="container-header container-startups">
				<Container>
							<ol className="breadcrumb">
								<li className="breadcrumb-item"><a href="/">TOP</a></li>
								<li className="breadcrumb-item" aria-current="page">CONTACT</li>
							</ol>
						<h2>CONTACT</h2>
				</Container>
			</section>
      <section className="container-contact-complete">
				<Container>
					<div className="content-wrap">
						<h2>送信完了しました</h2>
						<Row>
							<Col sm={{size:10, offset:1}} xs="12">
								<Card>
										<CardBody>
											<Row>
												<Col md={12}>
													<p>３営業日以内に返信いたします。</p>
												</Col>
											</Row>

												<Link to="/" ><Button className="btn-primary btn-submit">ok</Button></Link>

										</CardBody>
									</Card>
							</Col>
						</Row>

					</div>
				</Container>
			</section>
			</Fragment>
  );
};

export default ContactComplete;
