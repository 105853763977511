import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
//import CommentsContext from '../context/comments';

import request from "request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";

const sanitize_options = {
  allowedTags: [
    "b",
    "i",
    "em",
    "strong",
    "a",
    "h1",
    "h2",
    "hr",
    "br",
    "div",
    "span",
    "font",
    "img",
    "li",
  ],
  allowedAttributes: {
    a: ["href"],
    img: ["src", "width"],
    font: ["color"],
  },
};

export default class ContentsDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      created_at: "",
      tag: "",
      title: "",
      body: "",
      uri: "",
      thumbnail: "",
      meta_title: "",
      keywords: "",
      description: "",
    };
  }

  componentDidMount() {
    var scope = this;
    try {
      var options = {
        uri:
          'https://accel-baas.firebaseio.com/articles.json?orderBy="uri"&endAt="' +
          this.props.article_id +
          '"&print=pretty',
        //uri: "https://accel-baas.firebaseio.com/articles.json?orderBy=\"uri\"&endAt=\"basics-of-sto\"&print=pretty"
      };
      request.get(options, function(error, response, body) {
        var article = JSON.parse(response.body);
        for (var k in article) {
          if (null != article[k] && moment().isAfter(article[k].created_at)) {
            article[k].body = article[k].body.replace(/[\n\r]/g, "<br />");
            scope.setState(article[k]);
          } else {
            scope.setState({
              created_at: "",
              tag: "",
              title: "",
              body: "Can not find the article. Please check the URL.",
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const list = this.state.articles;
    return (
      <Fragment>
        <section className="container-header container-contents-detail">
          <Container>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">TOP</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <a href="/contents/">Contents</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                {this.state.uri}
              </li>
            </ol>
            <h2>{this.state.title}</h2>
            <span className="date">
              {moment(this.state.created_at)
                .tz("Asia/Tokyo")
                .format("MMMM Do, YYYY")}{" "}
              #{this.state.tag}{" "}
              <a
                className="sns-icon"
                href={
                  "https://twitter.com/share?url=https%3A%2F%2Faccel-program.io%2Farticle%2F" +
                  this.props.article_id +
                  "&text=" +
                  this.state.title +
                  "&via=techfund_inc"
                }
                target="_blank"
              >
                <FontAwesomeIcon icon={["fab", "twitter-square"]} />
              </a>
            </span>
          </Container>
        </section>
        <section className="container-baige container-content-detail">
          <Container>
            <div className="content-wrap">
              <Row>
                <Col
                  sm="12"
                  xs="12"
                  dangerouslySetInnerHTML={{ __html: this.state.body }}
                ></Col>
              </Row>

              <div className="">
                <ul className="pagination pagination-lg flex-center">
                  {/*<li class="page-item"><a href="#" class="page-link" aria-label="Previous"><span aria-hidden="true">‹</span><span class="sr-only">Previous</span></a></li>*/}
                  <li className="page-item back-to-list">
                    <a className="page-link" href="/contents">
                      back to list
                    </a>
                  </li>
                  {/*<li class="page-item"><a href="#" class="page-link" aria-label="Next"><span aria-hidden="true">›</span><span class="sr-only">Next</span></a></li>*/}
                </ul>
              </div>
            </div>
          </Container>
        </section>

        {/*
			<section className="container-baige container-news">
				<Container>
					<div className="content-wrap">
					<h2>New Contents</h2>

						<Card className="mb-4">
							<Row>
								<Col sm="4" xs="12">
									<div className="image-wrap">
										<img className="thumb-100" src="/assets/images/news/sample.png" />
										<span className="tag tag-article">Article</span>
									</div>
								</Col>
								<Col sm="8" xs="12">
									<h3>Coporated with Securitize</h3>
									<span className="date">2019/06/12</span>
									<p className="mt-2">
									起業家を支援する起業家を支援する。 起業家を支援する起業家を支援する起業家を支援する起業家を支援する。
									</p>
								</Col>
							</Row>
						</Card>

					</div>

				</Container>
			</section>
			*/}
      </Fragment>
    );
  }
}
