import React from 'react'
import Layout from '../../layouts/';
import ApplyComplete from '../../components/ApplyComplete';
const ApplyCompletePage = () => (
  <Layout lang="en">
		<ApplyComplete lang="en"/>
  </Layout>
)

export default ApplyCompletePage

