import React from 'react'
import Layout from '../../layouts/';
import ContactComplete from '../../components/ContactComplete';
const ContactCompletePage = () => (
  <Layout lang="en">
		<ContactComplete lang="en"/>
  </Layout>
)

export default ContactCompletePage

