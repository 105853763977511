import React, { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card } from "reactstrap";
//import CommentsContext from '../context/comments';

import request from "request";
import moment from "moment-timezone";

export default class Contents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
    };
  }

  componentDidMount() {
    var scope = this;
    try {
      var options = {
        uri: "https://accel-baas.firebaseio.com/articles.json",
      };
      request.get(options, function(error, response, body) {
        var articles = JSON.parse(response.body);
        for (let i in articles) {
          let old_articles = scope.state.articles;
          old_articles.push(articles[i]);
          scope.setState({ articles: old_articles });
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

  link(uri) {
    window.location.href = "/content-details/" + uri;
  }

  render() {
    const list = this.state.articles;
    return (
      <Fragment>
        <section className="container-header container-contents">
          <Container>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">TOP</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Contents
              </li>
            </ol>
            <h2>Contents</h2>
          </Container>
        </section>

        <section className="container-baige container-news">
          <Container>
            <div className="content-wrap">
              {list.map((v) => {
                let body_txt = v.body
                  .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
                  .slice(0, 250);
                if (250 < v.body.length) {
                  body_txt += "...";
                }
                return (
                  <Card
                    className="mb-4"
                    onClick={(e) => {
                      this.link(v.uri);
                    }}
                  >
                    <Row>
                      <Col sm="4" xs="12">
                        <div className="image-wrap">
                          <img className="thumb-100" src={v.thumbnail} />
                          <span className="tag tag-article">Article</span>
                        </div>
                      </Col>
                      <Col sm="8" xs="12">
                        <h3>{v.title}</h3>
                        <span className="date">
                          {moment(v.created_at)
                            .tz("Asia/Tokyo")
                            .format("MMMM Do, YYYY")}
                        </span>
                        <p className="mt-2">{body_txt}</p>
                      </Col>
                    </Row>
                  </Card>
                );
              })}
              <div className="">
                <ul className="pagination pagination-lg flex-center">
                  <li class="page-item">
                    <a href="#" class="page-link" aria-label="First">
                      <span aria-hidden="true">«</span>
                      <span class="sr-only">First</span>
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link" aria-label="Previous">
                      <span aria-hidden="true">‹</span>
                      <span class="sr-only">Previous</span>
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link" aria-label="Next">
                      <span aria-hidden="true">›</span>
                      <span class="sr-only">Next</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </section>
      </Fragment>
    );
  }
}
